import React, { useRef, useEffect, useState } from "react";

const DynamicTextLeftt = ({
  SouthAmerica,
  currencySymbol,
  integerPart,
  currencypl,
  decimalPart,
  top = true,
}) => {
  const containerRef = useRef(null); // Kapsayıcı referansı
  const [fontSize, setFontSize] = useState({ main: "17pt", small: "10pt" }); // Dinamik font boyutu
  console.log("currro",currencypl);
  useEffect(() => {
    const adjustFontSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth < 70) {
          // 150'den küçük genişlik için
          setFontSize({ main: "17pt", small: "10pt" });
        } else if (containerWidth >= 70 && containerWidth < 100) {
          // 150 ile 200 arasındaki genişlik için
          setFontSize({ main: "17pt", small: "9.41pt  " });
        } else if (containerWidth >= 100) {
          // 200 ve üzeri genişlik için
          setFontSize({ main: "14pt", small: "8.24pt" });
        }
      }
    };
  
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize); // Dinamik ekran boyutlandırma
    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);
  
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "0 auto",
      }}
    >
      <div
                  ref={containerRef}

        style={{
          position: "relative",
          overflow: "hidden",
          height: 30,
          paddingTop: 5,
          paddingBottom: 5,
          display: "flex",
          margin: "0 auto",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
            transition: "transform 0.3s ease",
        
          }}

        >
          <p
            className="helve-condensed"
            style={{ fontSize: fontSize.main, marginRight: "5px" }}
          >
            {SouthAmerica ? (top ? "AUD$" : "CLP$") : currencySymbol}
          </p>
          <p className="helve-condensed" style={{ fontSize: fontSize.main }}>
            {integerPart}
          </p>
      {currencypl !== "CLP" && (
            <p
              className="helve-condensed"
              style={{
                fontSize: fontSize.small,
                marginTop: "-4px",
                marginRight: "4px",
              }}
            >
              {decimalPart}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicTextLeftt;
