import React, { useEffect, useState } from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import Page1 from './Page1'
import Page2Kids from './Page2Kids'
import PageAccWoman from './PageAccWoman'
import { ReactComponent as Zaraön } from '../../../../../assets/Varlık2.svg'
import useOrderStore from 'store/client/orderStore'
import { IoTriangleOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import { ReactComponent as ZaraBom } from '../../../../../assets/DD.svg'
import { ReactComponent as ZaraBomRed } from '../../../../../assets/DDRED.svg'
import { ReactComponent as AUDDOLAR } from '../../../../../assets/DDRED.svg'
import { ReactComponent as ZaraAcc } from '../../../../../assets/zaraaccess01.svg'
import { ReactComponent as ZaraAccLinge } from '../../../../../assets/linnngee.svg'
import { ReactComponent as ZaraAccEmty } from '../../../../../assets/accesemty.svg'
import { ReactComponent as ZaraAccLin } from '../../../../../assets/accesslin.svg'




import DynamicTextLeftt from './DynamicShrinkLefft'
const L55XPR = ({ data, selectColor, tab }, ref) => {
  const department = data.department
  const datt =data;
  const [brand, category, subCategory] = department.split(' / ')
  const [SouthAmerica, setSouthAmerica] = useState(false)
  const [loading, setLoading] = useState(true);

  const [harmacn, setHarmacn] = useState(false) // harmacn state'i

  const { referance } = useOrderStore()
  console.log('category', category)
  console.log('brand', brand)
  console.log('subcategory', subCategory)




  function splitCurrency(value) {
    // Sayıyı string'e çevir
    const stringValue = value.toString()

    // Ondalık noktaya göre ayır
    let [integerPart, decimalPart] = stringValue.split('.')

    // Ondalık kısmı kontrol et ve düzenle
    if (!decimalPart) {
      // Ondalık kısım yoksa, 00 olarak ayarla
      decimalPart = '00'
    } else if (decimalPart.length === 1) {
      // Ondalık kısım tek haneliyse, sonuna bir 0 ekle
      decimalPart += '0'
    } else if (decimalPart.length > 2) {
      // Ondalık kısım iki haneden fazlaysa, ilk iki haneyi al
      decimalPart = decimalPart.substring(0, 2)
    }

    // Sonuçları döndür
    return [integerPart, decimalPart]
  }

  useEffect(() => {
    // South America kontrolü
    if (data.brandOrderNo && data.brandOrderNo.trim().endsWith('-25')) {
      setSouthAmerica(true);
    } else {
      setSouthAmerica(false);
    }
    // Harmacn kontrolü
    if (data.companyId === "6562742a5b252fdddcc588e7") {
      setHarmacn(true);
    } else {
      setHarmacn(false);
    }
  }, [data.brandOrderNo]);


  function getCurrencySymbol(type) {
    switch (type) {
      case 'AUD':
        return '$' // Avustralya Doları için dolar işareti
      case 'USD':
        return '$' // Avustralya Doları için dolar işareti
      case 'EUR':
        return '€' // Euro için euro işareti
      case 'BRL':
        return 'R$' // Brezilya Reali için Real işareti
      case 'GBP':
        return '£' // Brezilya Reali için Real işareti
      case 'CLP':
        return '$' // Brezilya Reali için Real işareti
      default:
        return '' // Diğer durumlar için boş string (veya belki başka bir varsayılan sembol)
    }
  }

  const [currentShape, setCurrentShape] = useState(null)
  const isBlack = referance?.backgroundColor === 'B'

  const [integerPart, decimalPart] = splitCurrency(data.priceLabel[0].mix[0].currency)
  const [integerPart2, decimalPart2] = splitCurrency(data.priceLabel[0].mix[1].currency)
  const currencySymbol = getCurrencySymbol(data.priceLabel[0].mix[0].type)
  const currencySymbol2 = getCurrencySymbol(data.priceLabel[0].mix[1].type)

  let integerPart3, decimalPart3, currencySymbol3, integerPart4, decimalPart4, currencySymbol4

  if (data.priceLabel[0].mix.length > 2) {
    ;[integerPart3, decimalPart3] = splitCurrency(data.priceLabel[0].mix[2].currency)
    currencySymbol3 = getCurrencySymbol(data.priceLabel[0].mix[2].type)
  } else {
    integerPart3 = decimalPart3 = currencySymbol3 = ''
  }

  if (data.priceLabel[0].mix.length > 3) {
    ;[integerPart4, decimalPart4] = splitCurrency(data.priceLabel[0].mix[3].currency)
    currencySymbol4 = getCurrencySymbol(data.priceLabel[0].mix[3].type)
  } else {
    integerPart4 = decimalPart4 = currencySymbol4 = ''
  }

  // item.amount > 0 koşulunu sağlayan öğeleri filtrele ve her 8 öğede bir grupla
  const filteredAndGroupedSizes = data.priceLabel[selectColor].sizes
    .filter((item) => item.amount > 0)
    .reduce((acc, item, index) => {
      const groupIndex = Math.floor(index / 3) // Her 3 öğe için bir grup indeksi
      if (!acc[groupIndex]) {
        acc[groupIndex] = { items: [], startIndex: index - (index % 3) } // Yeni bir grup oluştur
      }
      acc[groupIndex].items.push(item)
      return acc
    }, [])

  return (
    
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ref={ref}>
    
      {filteredAndGroupedSizes.map((groupObj, groupIndex) => (
        <A4Page key={groupIndex} type="yatay">
          <div className="space-y-5" style={{ position: 'relative' }}>
            {category === 'Kids' ? (
              // Eğer category === "Kids" ise bu div render edilecek
              <div
                style={{
                  width: '55.2mm',
                  height: '95.2mm',
                  border: '1px solid black',
                  position: 'absolute',
                  top: '135px',
                  left: `26px`
                }}
              ></div>
            ) : subCategory === 'ACC WOMAN' ? (
              // Eğer subCategory === "ACC WOMAN" ise bu div render edilecek
              <></>
            ) : (
              // Eğer yukarıdaki şartların hiçbiri sağlanmazsa bu div render edilecek
              <div
                style={{
                  width: '55.2mm',
                  height: '110.2mm',
                  border: '1px solid black',
                  position: 'absolute',
                  top: '135px',
                  left: `26px`
                }}
              ></div>
            )}

            <TopDetail values={data} selectColor={selectColor} tab={tab} />
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 22 }}>
              {category === 'Kids' ? (
                <div
                  className="printable"
                  style={{
                    position: 'relative',
                    marginRight: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 104
                  }}
                >
                  {isBlack ? (
                    <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                  ) : (
                    <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      top: '25px',
                      left: '45.64%',
                      background: 'none'
                    }}
                  >
                    <ZaraBomRed width="4.4mm" height="4.4mm"></ZaraBomRed>
                  </div>
                </div>
              ) : subCategory === 'ACC WOMAN' ? (
                <div
                  style={{
                    position: 'relative',
                    marginRight: 24,
                    display: 'flex',
                    flexDirection: 'column',

                    alignItems: 'center'
                  }}
                >
                  {referance?.code === 'HPZWLIN001' ? (
                    <ZaraAccLinge style={{ width: '55.2mm', height: '80.2mm' }} />
                  ) : (
                    <ZaraAcc style={{ width: '55.2mm', height: '80.2mm' }} />
                  )}
                </div>
              ) : (
                <div
                  className="printable"
                  style={{
                    position: 'relative',
                    marginRight: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 157
                  }}
                >
                  {isBlack ? (
                    <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                  ) : (
                    <Zaraön style={{ width: '37mm', height: '15.438mm' }} />
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      top: '25px',
                      left: '45.64%',
                      background: 'none'
                    }}
                  >
                    <ZaraBomRed width="4.4mm" height="4.4mm"></ZaraBomRed>
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginRight: 12
                }}
              >
                <div
                  style={{
                    width: '31mm',
                    height: '12mm',
                    borderRadius: '5px',
                    borderColor: '#6d6b6e',
                    padding: '5px', // Padding değerini azalttım
                    display: 
      (!data.priceLabel[0].mix[0].currency && data.priceLabel[0].mix[1].currency && 
      (data.priceLabel[0].mix[2].currency || data.priceLabel[0].mix[3].currency))
        ? 'none' 
        : 'grid', // Koşul
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    overflow: 'hidden' // Taşmayı engellemek için
                  }}
                >
             <div
  style={{

    height: '0.9mm',
    backgroundColor: '#c8102e',
    width: '24.207mm',
    margin: 'auto', // Bu, div'i yatay olarak ortalar
    marginTop: '3px'
  }}
></div>
                  <DynamicTextLeftt
                    SouthAmerica={SouthAmerica}
                    currencySymbol={currencySymbol}
                    integerPart={integerPart}
                    decimalPart={decimalPart}
                    top={true}
                  />
                </div>
                <div
                  style={{
                    width: '37mm',
                    height: '20mm',
                    borderRadius: '5px',
                    borderColor: '#6d6b6e',
                    padding: '25px', // Padding değerini azalttım
                    display: 'grid',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    overflow: 'hidden' // Taşmayı engellemek için
                  }}
                >
                 <div
                style={{
                  height: '0.9mm',
                  backgroundColor: 
                    (!data.priceLabel[0].mix[0].currency && data.priceLabel[0].mix[1].currency && (data.priceLabel[0].mix[2].currency || data.priceLabel[0].mix[3].currency)) 
                      ? '#000' // Koşul sağlanıyorsa siyah
                      : '#003da5', // Koşul sağlanmıyorsa mavi
                  width: '27.275mm',
                  margin: 'auto', // Bu, div'i yatay olarak ortalar
                  marginTop: '-3px',
                }}
              ></div>
                  <DynamicTextLeftt
                    SouthAmerica={SouthAmerica}
                    currencySymbol={currencySymbol2}
                    integerPart={integerPart2}
                    currencypl={data.priceLabel[0].mix[1].type}
                    decimalPart={decimalPart2}
                    top={false}
                  />
                </div>
              </div>
              {groupObj.items.map((sizeData, index) => (
                <div>
                  {category === 'Kids' ? (
                    <div
                      style={{
                        width: '55.2mm',
                        height: '95.2mm',
                        border: '1px solid red',
                        position: 'absolute',
                        top: '134px',
                        right: `${493 - index * 230}px`
                      }}
                    ></div>
                  ) : subCategory === 'ACC WOMAN' ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: '134px',
                        right: `${493 - index * 230}px`
                      }}
                    >
                  {referance?.code === 'HPZCALL005' ? (
                        <ZaraAccEmty style={{ width: '55.2mm', height: '80.2mm' }} />
                      ) : (
                        <ZaraAccLin style={{ width: '55.2mm', height: '80.2mm' }} />
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '55.2mm',
                        height: '110.2mm',
                        border: '1px solid red',
                        position: 'absolute',
                        top: '134px',
                        right: `${493 - index * 230}px`
                      }}
                    ></div>
                  )}

                  <div className="printable" style={{ position: 'relative', marginRight: 10 }}>
  {category === 'Kids' ? (
    <Page2Kids
      key={groupObj.startIndex + index} // Global index kullanımı
      selectColor={selectColor}
      barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.priceLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
      data={data}
      harmacn={harmacn}
      sizeData={sizeData}
      indo={data?.priceLabel[0]?.sizes}
      szrgrp={data?.sizeGroup}
      inx={groupObj.startIndex + index} // Global index kullanımı
      integerpart3={integerPart3}
      currencysymbol3={currencySymbol3}
      SouthAmerica={SouthAmerica}
      decimalpart3={decimalPart3}
      decimalpart4={decimalPart4}
      integerpart4={integerPart4}
      currencysymbol4={currencySymbol4}
      rifd={data.priceLabel[0].rifd}
    />
  ) : subCategory === 'ACC WOMAN' ? (
    <PageAccWoman
      key={groupObj.startIndex + index} // Global index kullanımı
      selectColor={selectColor}
      barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.priceLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
      data={data}
      harmacn={harmacn}

      sizeData={sizeData}
      indo={data?.priceLabel[0]?.sizes}
      szrgrp={data?.sizeGroup}
      inx={groupObj.startIndex + index} // Global index kullanımı
      integerpart3={integerPart3}
      currencysymbol3={currencySymbol3}
      decimalpart3={decimalPart3}
      decimalpart4={decimalPart4}
      integerpart4={integerPart4}
      currencysymbol4={currencySymbol4}
      rifd={data.priceLabel[0].rifd}
    />
  ) : (
    <Page1
      key={groupObj.startIndex + index} // Global index kullanımı
      selectColor={selectColor}
      barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.priceLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
      data={data}
      harmacn={harmacn}

      SouthAmerica={SouthAmerica}
      sizeData={sizeData}
      indo={data?.priceLabel[0]?.sizes}
      szrgrp={data?.sizeGroup}
      inx={groupObj.startIndex + index} // Global index kullanımı
      integerpart3={integerPart3}
      currencysymbol3={currencySymbol3}
      decimalpart3={decimalPart3}
      decimalpart4={decimalPart4}
      integerpart4={integerPart4}
      currencysymbol4={currencySymbol4}
      rifd={data.priceLabel[0].rifd}
    />
  )}
</div>

                </div>
              ))}
            </div>
          </div>
        </A4Page>
      ))}
    </div>
  )
}

export default React.forwardRef(L55XPR)
