import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Sheet } from 'lucide-react'

export default function Excel() {
  const data = [
    {
      NO: 1,
      MPO: '',
      ORDERNO: '23729-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: '134277/10178-1',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '11667',
      ARTICLE: '5584/461',
      FROMADDRESS: '',
      COLORCODE: '400',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DO NOT DRY CLEAN',
      SIZETYPE: 'KADIN ALT - RAKAMLI',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: '32',
      USA: '32',
      EUROPE: '32',
      MEXICO: '32',
      ITA: '32',
      BR_BRASIL: '32',
      CN: '160/58A',
      QTY: '5',
      RATEQTY: '5',
      Content: '',
      CreateDate: '2023-12-25T11:13:00.001Z'
    },
    {
      NO: 1,
      MPO: '',
      ORDERNO: '23729-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: '134277/10178-1',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '11667',
      ARTICLE: '5584/461',
      FROMADDRESS: '',
      COLORCODE: '400',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DO NOT DRY CLEAN',
      SIZETYPE: 'KADIN ALT - RAKAMLI',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: '34',
      USA: '34',
      EUROPE: '34',
      MEXICO: '34',
      ITA: '34',
      BR_BRASIL: '34',
      CN: '165/62A',
      QTY: '5',
      RATEQTY: '5',
      Content: '',
      CreateDate: '2023-12-25T11:13:00.001Z'
    },
    {
      NO: 1,
      MPO: '',
      ORDERNO: '23729-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: '134277/10178-1',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '11667',
      ARTICLE: '5584/461',
      FROMADDRESS: '',
      COLORCODE: '400',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DO NOT DRY CLEAN',
      SIZETYPE: 'KADIN ALT - RAKAMLI',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: '36',
      USA: '36',
      EUROPE: '36',
      MEXICO: '36',
      ITA: '36',
      BR_BRASIL: '36',
      CN: '165/66A',
      QTY: '5',
      RATEQTY: '5',
      Content: '',
      CreateDate: '2023-12-25T11:13:00.001Z'
    },
    {
      NO: 2,
      MPO: '',
      ORDERNO: '11111-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: 'SN:S12449 TN:601702',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '3905',
      ARTICLE: '3905/532',
      FROMADDRESS: '',
      COLORCODE: '800',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DRY CLEAN TETRACHLORETHYLENE',
      SIZETYPE: 'KADIN ÜST - HARFLİ',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: 'S',
      USA: 'S',
      EUROPE: 'S',
      MEXICO: 'S',
      ITA: 'S',
      BR_BRASIL: 'S',
      CN: '165/84A',
      QTY: '5000',
      RATEQTY: '5000',
      Content: '',
      CreateDate: '2023-12-25T11:14:34.721Z'
    },
    {
      NO: 2,
      MPO: '',
      ORDERNO: '11111-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: 'SN:S12449 TN:601702',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '3905',
      ARTICLE: '3905/532',
      FROMADDRESS: '',
      COLORCODE: '800',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DRY CLEAN TETRACHLORETHYLENE',
      SIZETYPE: 'KADIN ÜST - HARFLİ',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: 'M',
      USA: 'M',
      EUROPE: 'M',
      MEXICO: 'M',
      ITA: 'M',
      BR_BRASIL: 'M',
      CN: '170/88A',
      QTY: '8352',
      RATEQTY: '8352',
      Content: '',
      CreateDate: '2023-12-25T11:14:34.721Z'
    },
    {
      NO: 2,
      MPO: '',
      ORDERNO: '11111-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: 'SN:S12449 TN:601702',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '3905',
      ARTICLE: '3905/532',
      FROMADDRESS: '',
      COLORCODE: '800',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DRY CLEAN TETRACHLORETHYLENE',
      SIZETYPE: 'KADIN ÜST - HARFLİ',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: 'L',
      USA: 'L',
      EUROPE: 'L',
      MEXICO: 'L',
      ITA: 'L',
      BR_BRASIL: 'L',
      CN: '175/96A',
      QTY: '5489',
      RATEQTY: '5489',
      Content: '',
      CreateDate: '2023-12-25T11:14:34.721Z'
    },
    {
      NO: 3,
      MPO: '',
      ORDERNO: '26809-25',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: '9000/671',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '2795',
      ARTICLE: '9000/671',
      FROMADDRESS: '',
      COLORCODE: '401',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DO NOT DRY CLEAN',
      SIZETYPE: 'KADIN ÜST - HARFLİ',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: 'XXS',
      USA: 'XXS',
      EUROPE: 'XXS',
      MEXICO: 'XXS',
      ITA: 'XXS',
      BR_BRASIL: 'XXS',
      CN: '155/76A',
      QTY: '1000',
      RATEQTY: '1000',
      Content: '',
      CreateDate: '2023-12-25T12:16:42.405Z'
    },
    {
      NO: 4,
      MPO: '',
      ORDERNO: '23729-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: '134277/10178-1',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '11667',
      ARTICLE: '5584/461',
      FROMADDRESS: '',
      COLORCODE: '400',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 30 C/86 F GENTLE CYCLE; DO NOT BLEACH; TUMBLE DRY LOW; IRON UP TO 110 C / 230 F; DRY CLEAN TETRACHLORETHYLENE',
      SIZETYPE: 'KADIN ALT - RAKAMLI',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: '32',
      USA: '32',
      EUROPE: '32',
      MEXICO: '32',
      ITA: '32',
      BR_BRASIL: '32',
      CN: '160/58A',
      QTY: '10',
      RATEQTY: '10',
      Content: '',
      CreateDate: '2023-12-29T10:15:41.385Z'
    },
    {
      NO: 5,
      MPO: '',
      ORDERNO: '22205-D',
      BRAND: 'Zara',
      DATEEDIT: '',
      SUPPLIER: '',
      BUYER: 'SSSS',
      SEASON: 'S2024',
      PAYMENTTERMS: '',
      INCOTERMS: '',
      SUPPLIERREF: '3905',
      ARTICLE: '5555/555',
      FROMADDRESS: '',
      COLORCODE: '500',
      DESCRIPTION: 'Woman WOMAN-WOMAN PREMIUM DENIM',
      EXCLUSIVE: '',
      HANDOVERDATE: '',
      MADEIN: 'MADE IN TURKEY',
      WASH: 'MACHINE WASH UP TO 95 C/203 F GENTLE CYCLE; DO NOT BLEACH; DO NOT TUMBLE; IRON UP TO 110 C / 230 F; DO NOT DRY CLEAN',
      SIZETYPE: 'KADIN ÜST - RAKAMLI',
      SIZEGROUP: 'S | M | L | XL',
      SIZE: '32',
      USA: '32',
      EUROPE: '32',
      MEXICO: '32',
      ITA: '32',
      BR_BRASIL: '32',
      CN: '160/76A',
      QTY: '1',
      RATEQTY: '1',
      Content: '',
      CreateDate: '2023-12-29T13:08:06.837Z'
    }
  ]

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('SemonRapor')
    console.log("buradaaaaaa")
    worksheet.columns = [
      { header: 'NO', key: 'NO' },
      { header: 'MPO', key: 'MPO' },
      { header: 'ORDERNO', key: 'ORDERNO' },
      { header: 'BRAND', key: 'BRAND' },
      { header: 'DATEEDIT', key: 'DATEEDIT' },
      { header: 'SUPPLIER', key: 'SUPPLIER' },
      { header: 'BUYER', key: 'BUYER', width: 30 },
      { header: 'SEASON', key: 'SEASON' },
      { header: 'PAYMENTTERMS', key: 'PAYMENTTERMS' },
      { header: 'INCOTERMS', key: 'INCOTERMS' },
      { header: 'SUPPLIERREF', key: 'SUPPLIERREF' },
      { header: 'ARTICLE', key: 'ARTICLE' },
      { header: 'FROMADDRESS', key: 'FROMADDRESS' },
      { header: 'COLORCODE', key: 'COLORCODE' },
      { header: 'DESCRIPTION', key: 'DESCRIPTION', width: 30 },
      { header: 'EXCLUSIVE', key: 'EXCLUSIVE' },
      { header: 'HANDOVERDATE', key: 'HANDOVERDATE' },
      { header: 'MADEIN', key: 'MADEIN', width: 20 },
      { header: 'WASH', key: 'WASH', width: 30 },
      { header: 'SIZETYPE', key: 'SIZETYPE' },
      { header: 'SIZEGROUP', key: 'SIZEGROUP' },
      { header: 'SIZE', key: 'SIZE' },
      { header: 'USA', key: 'USA' },
      { header: 'EUROPE', key: 'EUROPE' },
      { header: 'MEXICO', key: 'MEXICO' },
      { header: 'ITA', key: 'ITA' },
      { header: 'BR_BRASIL', key: 'BR_BRASIL' },
      { header: 'CN', key: 'CN' },
      { header: 'QTY', key: 'QTY' },
      { header: 'RATEQTY', key: 'RATEQTY' },
      { header: 'Content', key: 'Content' },
      { header: 'CreateDate', key: 'CreateDate' }
    ]

    data.forEach((item) => {
      worksheet.addRow(item)
    })

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, 'SemonRapor.xlsx')
      })
      .catch((err) => console.error(err))
  }

  return (
    <div className="inline-block cursor-pointer" onClick={downloadExcel}>
      <div className="bg-green-800 p-3 text-sm text-white flex items-center rounded">
        <Sheet size={20} className="mr-2" />
        Excel Dosyanız Hazır İndirmek İçin Tıklayın
      </div>
    </div>
  )
}
