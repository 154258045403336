import React, { useRef, useEffect, useState } from 'react'

import useOrderStore from 'store/client/orderStore'

import { ReactComponent as MySVG20 } from '../../../../../assets/usuk.svg'
import { ReactComponent as MySVG249 } from '../../../../../assets/justus.svg'
import { ReactComponent as MySVG250 } from '../../../../../assets/justuk.svg'
import { ReactComponent as MySVG2900 } from '../../../../../assets/RDOLAR.svg'
import { ReactComponent as MyRed } from '../../../../../assets/waist/164.svg'
import { ReactComponent as MyZara } from '../../../../../assets/waist/ZARALITTLE.svg'



export default function PageKallLittle({
  sizeData,
  selectColor = 0,
  barcodeValue,
  data,
  indo,
  inx,
  szrgrp,
  integerpart3,
  currencysymbol3,
  integerpart4,
  currencysymbol4,
  decimalpart3,
  decimalpart4,
  rifd,
  SouthAmerica,
  harmacn
}) {
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [scaleX, setScaleX] = useState(1)
  const department = data.department
  const barcodeRef = useRef(null)
  const [modifiedSvgText, setModifiedSvgText] = useState('')
  const [topValue, settopValue] = useState(16)
  const [leftValue, setleftValue] = useState(97.9)
  // Bölmek için "/" karakterini kullan
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  const [brand, category, subCategory] = department.split(' / ')
  const filteredIndo = indo.filter((item) => item.amount !== '')
  const [topValuexx, setTopValuexx] = useState(null);
  const [topValueSecondlinexx, setTopValueSecondlinexx] = useState(null);
  const [topThreeLine, setTopThreeLine] = useState(null);
  const [topFourLine, setTopFourLine] = useState(null);

  const [topValuexxreverse, settopValuexxreverse] = useState(null);
  const [topValueSecondlinexxreverse, settopValueSecondlinexxreverse] = useState(null);
  const [topThreeLinereverse, settopThreeLinereverse] = useState(null);
  const [topFourLinereverse, settopFourLinereverse] = useState(null);

  const [fsize, setFsize] = useState(null);
  const line2Ref = useRef(null);

  const [fontSize, setFontSize] = useState(20); // Başlangıç font boyutu

  const indexedIndo = indo.reduce((acc, item, index) => {
    if (item.amount !== '') {
      acc.push(index)
    }
    return acc
  }, [])
  const isNotEmpty = (val) => val !== undefined && val !== null && val !== '' && val !== 'null';

  useEffect(() => {
    if (referance) {
      console.log("buradaaas",referance.line)

      if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        !isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(297.21);
        settopValuexxreverse(76)
      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        !isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(286.21);
        setTopValueSecondlinexx(306.21);
        settopValuexxreverse(87)
        settopValueSecondlinexxreverse(67)

      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(204);
      } else if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(286.21);
        setTopThreeLine(302);
        setTopFourLine(311.2);
      } else if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(314.21);
      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(272);
        setTopValueSecondlinexx(290.3);
        setTopThreeLine(305);
        setTopFourLine(314.2);
      } else {
        // Default values if none of the conditions are met

      }
    }
  }, [referance]);
  
  
  useEffect(() => {
    const adjustFontSize = () => {
      if (line2Ref.current) {
        const { scrollHeight, offsetHeight } = line2Ref.current;
        console.log(`scrollHeight: ${scrollHeight}, offsetHeight: ${offsetHeight}`);
        if (offsetHeight > 51) {
          console.log(`Font size before adjustment: ${fontSize}`);
          setFontSize(17);
        }
        if (
          isNotEmpty(referance?.line1) &&
          isNotEmpty(referance?.line2) &&
          isNotEmpty(referance?.line3) &&
          isNotEmpty(referance?.line4)
        ) {
          setFontSize(17.2);
   
        } 
      }
   
    };
  
    // İlk render sonrası küçük bir gecikme ile kontrol
    setTimeout(adjustFontSize, 0);

  }, [line2Ref.current]);

  const renderSVG = (integer3, decimal3, integer4, decimal4) => {
    console.log('Values received:', { integer3, decimal3, integer4, decimal4 })
    console.log('SUBCATEGORYY', subCategory)
    // Değerleri stringe çevirip kontrol ediyoruz
    const isInteger3Valid = String(integer3).trim() !== ''
    const isDecimal3Valid = String(decimal3).trim() !== ''
    const isInteger4Valid = String(integer4).trim() !== ''
    const isDecimal4Valid = String(decimal4).trim() !== ''
    if (SouthAmerica) {
      return <MySVG2900 width="23.256mm" height="14.496mm" />
    } else {
      switch (true) {
        case isInteger3Valid && isDecimal3Valid && isInteger4Valid && isDecimal4Valid:
          console.log('Case 1: All values are present (integer3, decimal3, integer4, decimal4)')
          return <MySVG20 width="21.256mm" height="14.496mm" />
        case isInteger3Valid && isDecimal3Valid && !isInteger4Valid:
          console.log(
            'Case 2: Only integer3 and decimal3 are present, integer4 and decimal4 are missing'
          )
          return <MySVG249 width="21.256mm" height="14.496mm" />
        case isInteger4Valid && isDecimal4Valid && !isInteger3Valid:
          console.log(
            'Case 3: Only integer4 and decimal4 are present, integer3 and decimal3 are missing'
          )
          return <MySVG250 width="21.256mm" height="14.496mm" />
        default:
          console.log('Default Case: None of the conditions are met', {
            integer3,
            decimal3,
            integer4,
            decimal4
          })
          return null // Hiçbir koşul sağlanmazsa hiçbir şey döndürme
      }
    }
  }

  const valueBasedOnInx = indexedIndo[inx]

  const [currentShape, setCurrentShape] = useState(null)



  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
 

  


      <div
        style={{
          position: 'absolute',
          top: 192,
          left: 35.3,
          width: 55,
          zIndex: 4,

          padding: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '18px',
            padding: 0
          }}
        >
         
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 116,
          left: 0,
          width: "100%",
          zIndex: 4,
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          padding: 0,
        }}
      >
       <span
            className="helve"
            style={{ fontWeight: 300, color: '#898A8D', fontSize: 11, marginRight: 2.4,letterSpacing:-.2}}
          >
          {filteredIndo[inx]?.name}
          </span>
         <span
            className="helve"
            style={{ fontWeight: 300, color: '#898A8D', fontSize: 11, marginRight: 2.7,letterSpacing:-.2}}
          >
          {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTHS' : 'YEARS'}
          </span>
      <span
            className="helve"
            style={{ fontWeight: 300, color: '#898A8D', fontSize: 11, marginRight: 2.5 }}
          >
            /
          </span>
          <span
            className="helve"
            style={{ fontWeight: 300, color: '#898A8D', fontSize: 11, marginRight: 2.5 }}
          >
           {filteredIndo[inx]?.cm?.slice(0, 3)}
          </span>
          <span
            className="helve"
            style={{ fontWeight: 300, color: '#898A8D', fontSize: 11, letterSpacing:-.2 }}
          >
            CM
          </span>


      </div>
      <div
  style={{
    position: 'absolute',
    top: 50.5,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
      transform: 'rotate(-180deg)', // Metni ters çevirir

  }}
>

        <span
          className="helve"
          style={{
            fontWeight: 600,
            color: '#898A8D',
            fontSize: 12.8,
            letterSpacing: 1.3, 

          }}
        >
          {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTHS' : 'YEARS'}
        </span>
   
</div>
      <div
  style={{
    position: 'absolute',
    top: 150,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this

  }}
>
        <span
          className="helve"
          style={{
            fontWeight: 600,
            color: '#898A8D',
            fontSize: 12.8,
            letterSpacing: 1.31,
            marginTop: 3,
            paddingRight: 1.4
          }}
        >
          {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTHS' : 'YEARS'}
        </span>
</div>
<div
  style={{
    position: 'absolute',
    top: topValuexxreverse,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
      transform: 'rotate(-180deg)', // Metni ters çevirir

  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 300,
      color: '#898A8D',
      fontSize: fontSize,
      letterSpacing: '1px',
    }}
  >
  {referance?.line1}
  </span>
  
</div>

{ referance?.line2 && (
        <div
          style={{
            position: 'absolute',
            top: topValueSecondlinexxreverse,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            transform: 'rotate(-180deg)', // Metni ters çevirir

          }}
        >
          <span
           ref={line2Ref}
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: fontSize,
              letterSpacing: '1px',
            }}
          >
             {referance?.line2}
          </span>
        </div>
      )}
<div
  style={{
    position: 'absolute',
    top: topValuexx,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 300,
      color: '#898A8D',
      fontSize: fontSize,
      letterSpacing: '1px',
    }}
  >
  {referance?.line1}
  </span>
  
</div>

<div
        style={{
          position: 'absolute',
          top: 114.21,
          left: 0,
          zIndex: 4,
        }}
      >
      <MyRed style={{width:"32.2mm", height:"5.2mm"}}/>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 240.5,
          left: 12,
          zIndex: 4,
        }}
      >
      <MyZara style={{width:"26.66mm", height:"11.2411mm"}}/>
      </div>
{ referance?.line2 && (
        <div
          style={{
            position: 'absolute',
            top: topValueSecondlinexx,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
           ref={line2Ref}
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: fontSize,
              letterSpacing: '1px',
            }}
          >
             {referance?.line2}
          </span>
        </div>
      )}

      { referance?.line3 && (
        <div
          style={{
            position: 'absolute',
            top: topThreeLine,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: 8,
              letterSpacing: '0.2px',
            }}
          >
            {referance?.line3}
          </span>
        </div>
      )}
      {referance?.line4 && (
        <div
          style={{
            position: 'absolute',
            top: topFourLine,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: 8,
              letterSpacing: '0.2px',
            }}
          >
            {referance?.line4}
          </span>
        </div>
      )}
     <div
  style={{
    position: 'absolute',
    top: 27,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
      transform: 'rotate(-180deg)', // Metni ters çevirir

  }}
>

<span
          className="helve"
          style={{
            fontWeight: 700,
            color: '#898A8D',
            fontSize: 26,
            padding: 0,
            lineHeight: '22px',
            letterSpacing: 0.8
          }}
        >
          {filteredIndo[inx]?.name}
        </span>
   
</div>
    <div
  style={{
    position: 'absolute',
    top: 170.5,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this

  }}
>
     <span
          className="helve"
          style={{
            fontWeight: 900,
            color: '#898A8D',
            fontSize: 26,
            padding: 0,
            lineHeight: '22px',
            transform: 'scale(1.05, 1)',
            letterSpacing: 0.2
          }}
        >
          {filteredIndo[inx]?.name}
        </span>
</div>
<div
  style={{
    position: 'absolute',
    top: 10.5,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
      transform: 'rotate(-180deg)', // Metni ters çevirir

  }}
>
   <span
            className="helve"
            style={{ fontWeight: 600, color: '#898A8D', fontSize: 13, marginRight: 5 }}
          >
            {filteredIndo[inx]?.cm?.slice(0, 3)}
          </span>
          <span
            className="helve"
            style={{ fontWeight: 600, color: '#898A8D', fontSize: 13, letterSpacing: 0.6 }}
          >
            CM
          </span>
</div>

<div
  style={{
    position: 'absolute',
    top: 187,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this

  }}
>
<span
            className="helve"
            style={{ fontWeight: 600, color: '#898A8D', fontSize: 13, marginRight: 5 }}
          >
            {filteredIndo[inx]?.cm?.slice(0, 3)}
          </span>
          <span
            className="helve"
            style={{ fontWeight: 600, color: '#898A8D', fontSize: 13, letterSpacing: 0.6 }}
          >
            CM
          </span>
</div>
      <div>
        <div
          style={{
            position: 'absolute',
            top: 270,
            left: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
         
        </div>
      </div>
    </div>
  )
}
