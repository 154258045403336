import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'

import BottomSection from './BottomSection'
import TopSection from './TopSection'
import MainTitle from 'components/MainTitle'
import { useTotalCount } from 'store/server/useDashboard'
import Spinner from 'components/spinner'
import { createOrderSchema } from 'schemas/orderSchemas'

export default function AddOrderForm() {
  const { brand, dept, subDept } = useParams()
  const { data, isFetching } = useTotalCount()

  if (isFetching) return <Spinner />

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <MainTitle back>Sipariş Ekle</MainTitle>
        <p className="font-semibold p-2 rounded-lg uppercase">
          {brand.split('_')[1]} / {dept.split('_')[1]} / {subDept}
        </p>
      </div>
      <Formik
        initialValues={{
          orderNo: data.orderCount + 1,
          company: '',
          customerOrderNo: '',
          changesForEveryProduct: false,
          season: '',
          country: '',
          brandOrderNo: '',
          supplierNo: '',
          articleNo: '',
          sizeGroup: '',
          washingLabel: [
            {
              customerOrderNo: '',
              colorCode: '',
              referanceCode: '',
              customerOrderDetail: '',
              sizeDefectivePercent: '',
              sizes: [],
              fabric: [
                {
                  name: '',
                  mix: [
                    { percent: '', type: '' },
                    { percent: '', type: '' }
                  ]
                }
              ],
              symbol: {},
              additionalPhrases: [
                {
                  id: ''
                }
              ],
              washingAdditionalPhrases: [
                {
                  id: ''
                }
              ]
            }
          ],
          warningLabel: [
            {
              customerOrderNo: '',
              colorCode: '',
              referanceCode: '',
              customerOrderDetail: '',
              sizeDefectivePercent: '',
              qauntity:'',
            }
          ],
             warningLabel: [
            {
              customerOrderNo: '',
              colorCode: '',
              referanceCode: '',
              customerOrderDetail: '',
              sizeDefectivePercent: '',
              qauntity:'',
            }
          ],
          beltLabel: [
            {
              customerOrderNo: '',
              colorCode: '',
              referanceCode: '',
              customerOrderDetail: '',
              sizeDefectivePercent: '',
              sizes: [],  
     
           
            }
          ],
          priceLabel: [
            {
              customerOrderNo: '',
              colorCode: '',
              referanceCode: '',
              customerOrderDetail: '',
              sizeDefectivePercent: '',
              rifd: false,
              sizes: [],  
              mix: [
               { currency: '', type: '' },
               { currency: '', type: '' },
               { currency: '', type: '' },
               { currency: '', type: '' },
              ] 
                  
           
            }
          ],
   
        }}
        validationSchema={createOrderSchema}
      >
        <Form className="space-y-5">
          <div className="rounded bg-white p-5 space-y-2">
            <TopSection />
          </div>
          <BottomSection />
        </Form>
      </Formik>
    </div>
  )
}
