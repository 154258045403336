import Spinner from 'components/spinner'
import { Button } from 'components/ui/button'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Sheet } from 'lucide-react'
import { useParams } from 'react-router-dom'
import { useCreateOrderReport } from 'store/server/useReport'

export default function Excel() {
  const { orderNo } = useParams()
  const { data, isFetching, refetch } = useCreateOrderReport({ id: orderNo });


  const downloadExcel = async  () => {
    const newData = await refetch(); // Veriyi yeniden çek ve güncel veriyi al
    if (!newData.data) return; // Eğer veri yoksa işlemi durdur

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('SemonRapor')

    worksheet.columns = [
      { header: 'NO', key: 'NO' },
      { header: 'MPO', key: 'MPO' },
      { header: 'ORDERNO', key: 'ORDERNO' },
      { header: 'BRAND', key: 'BRAND' },
      { header: 'DATEEDIT', key: 'DATEEDIT' },
      { header: 'SUPPLIER', key: 'SUPPLIER' },
      { header: 'BUYER', key: 'BUYER', width: 30 },
      { header: 'SEASON', key: 'SEASON' },
      { header: 'PAYMENTTERMS', key: 'PAYMENTTERMS' },
      { header: 'INCOTERMS', key: 'INCOTERMS', width: 30 },
      { header: 'SUPPLIERREF', key: 'SUPPLIERREF' },
      { header: 'ARTICLE', key: 'ARTICLE' },
      { header: 'FROMADDRESS', key: 'FROMADDRESS' },
      { header: 'COLORCODE', key: 'COLORCODE' },
      { header: 'DESCRIPTION', key: 'DESCRIPTION', width: 30 },
      { header: 'EXCLUSIVE', key: 'EXCLUSIVE' },
      { header: 'HANDOVERDATE', key: 'HANDOVERDATE' },
      { header: 'MADEIN', key: 'MADEIN', width: 20 },
      { header: 'WASH', key: 'WASH', width: 30 },
      { header: 'SIZETYPE', key: 'SIZETYPE' },
      { header: 'SIZEGROUP', key: 'SIZEGROUP' },
      { header: 'SIZE', key: 'SIZE' },
      { header: 'USA', key: 'USA' },
      { header: 'EUROPE', key: 'EUROPE' },
      { header: 'MEXICO', key: 'MEXICO' },
      { header: 'ITA', key: 'ITA' },
      { header: 'BR_BRASIL', key: 'BR_BRASIL' },
      { header: 'CN', key: 'CN' },
      { header: 'QTY', key: 'QTY' },
      { header: 'RATEQTY', key: 'RATEQTY' },
      { header: 'Content', key: 'Content' },
      { header: 'CreateDate', key: 'CreateDate' },
      { header: 'PRICE1', key: 'PRICE1' },
      { header: 'PRICE2', key: 'PRICE2' },
      { header: 'PRICE3', key: 'PRICE3' },
      { header: 'PRICE4', key: 'PRICE4' },
      { header: 'RIFD', key: 'RIFD' },




    ]

    newData.data.forEach((item) => {
      worksheet.addRow(item)
    })

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, 'SemonRapor.xlsx')
      })
      .catch((err) => console.error(err))
  }

  return (
    <div className="inline-block cursor-pointer">
      <Button
        className="bg-green-700 hover:bg-green-800"
        onClick={downloadExcel}
        disabled={isFetching}
      >
        {isFetching ? <Spinner size={20} className="mr-2" /> : <Sheet size={20} className="mr-2" />}
        Excel İndir
      </Button>
    </div>
  )
}
