import React, { useRef, useEffect, useState } from "react";
import { IoTriangleOutline } from "react-icons/io5";
import { IoTriangle } from "react-icons/io5";
import { LiaCircleSolid } from "react-icons/lia";
import { BiSolidCircle } from "react-icons/bi";


  const DynamicTextScale = ({ filteredIndo, inx, szrgrp, valueBasedOnInx,referance, brand,category,subCategory}) => {
  const containerRef = useRef(null); // Kapsayıcı referansı
  const contentRef = useRef(null); // İçerik referansı
  const [scale, setScale] = useState(1); // Dinamik ölçek
  const [currentShape, setCurrentShape] = useState(null);
  const [calculatedResult, setCalculatedResult] = useState(null);
  const data = [
    { value: 203, result: 5 },
    { value: 161, result: 17 },
    { value: 123, result: 20 },
  ];
  const [gap, setGap] = useState("5px"); // Dinamik gap
  const [marginLeft, setMarginLeft] = useState("0px"); // Dinamik marginLeft



  useEffect(() => {

    const adjustScale = () => {
      const mmToPx = (mm) => mm * 3.779528; // Milimetreden piksele dönüştürme faktörü
      const targetWidthPx = mmToPx(37.213); // 37.213 mm'yi piksele çevir
      if (containerRef.current && contentRef.current) {
        
        const containerWidth = containerRef.current.offsetWidth; // Kapsayıcı genişliği,
        const contentWidth = contentRef.current.scrollWidth; // İçerik genişliği
        if (containerWidth > 140) {
          // Farkı bul
          const excessWidth = containerWidth - 140;
          // Ölçekleme oranını hesapla
          const scaleFactor = (containerWidth - excessWidth) / containerWidth;
  
          // Yatayda daraltmayı uygula
          setScale(scaleFactor);
        } else if (contentWidth > containerWidth) {
          // İçerik genişse klasik ölçekleme
          setScale(containerWidth / contentWidth);
        } else {
          setScale(1); // Orijinal boyuta dön
        }

        const interpolate = (containerWidth, data) => {
            for (let i = 0; i < data.length - 1; i++) {
              const current = data[i];
              const next = data[i + 1];
      
              // Eğer targetValue, current ve next arasında ise
              if (containerWidth <= current.value && containerWidth >= next.value) {
                const slope =
                  (next.result - current.result) / (next.value - current.value);
                return current.result + slope * (containerWidth - current.value);
              }
            }
      
            // Eğer targetValue aralık dışında ise
            return null;
          };
      
          const result = interpolate(containerWidth, data);
          setCalculatedResult(result);
           if (filteredIndo[inx]?.name=="S") {
            setGap("8px");
            setMarginLeft("10px");

          } 
          else if(filteredIndo[inx]?.name=="M")
          {
            setGap("6px");
            setMarginLeft("6px");
          }
          else if(filteredIndo[inx]?.name=="L")
          {
            setGap("9px");
            setMarginLeft("13px");
          }
      }
    };
  
    adjustScale();
    window.addEventListener("resize", adjustScale); // Dinamik ekran boyutlandırma
    return () => window.removeEventListener("resize", adjustScale);
  }, [filteredIndo, inx]);

  return (
<div
  style={{
    position: "relative", // position: absolute yerine relative kullandım
    top:108,
    display: "flex",
    justifyContent: "center", // Yatayda ortalama
    alignItems: "center", // Dikeyde ortalama
    width: "100%", // Genişlik tam ekran
    margin: "0 auto", // Merkezi hizalama
  }}
>
  <div
        ref={containerRef}

        style={{
  position: "relative", // Sarmalayıcı pozisyon
  overflow: "hidden",
  maxWidth: "37.1mm",
  display: "flex",
  margin: "0 auto", // Ortalamak için
  transform: "translateX(6px)" // Sol tarafa +11px eklemek için
}}
  >
  <div
      ref={contentRef}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        transform: `scaleX(${scale})`,
       transformOrigin: "left",
        transition: "transform 0.3s ease",
      }}
  >
    <p
      className="helve-medium"
      style={{
        fontSize: "24pt",
        margin: "0",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        marginRight: 6,
        marginLeft:marginLeft,
      }}
    >
      {filteredIndo[inx]?.name || "N/A"}
    </p>

    <div
      style={{
        display: "flex",
        whiteSpace: "nowrap", 
        overflow: "hidden",
        gap: gap, // Dinamik gap
      }}
      
    >
      {[
        { label: "", value: szrgrp.sizes[valueBasedOnInx].usa },
        { label: "", value: szrgrp.sizes[valueBasedOnInx].mexico },
        { label: "", value: szrgrp.sizes[valueBasedOnInx].uk },
        { label: "", value: szrgrp.sizes[valueBasedOnInx].ita },
        { label: "", value: szrgrp.sizes[valueBasedOnInx].brBrasil },
      ].map((item, index,array) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",

          }}
        >
          <p
            className="helve-roman"
            style={{
              fontSize: "5pt",
              marginTop: "12px",
            }}
          >
            {item.label}
          </p>
          <p
            className="helve-medium"
            style={{
              fontSize: "12pt",
              marginTop: "-3px",
              margin: "0",
            }}
          >
            {item.value}
          </p>
        </div>
      ))}
    </div>
    </div>
  </div>
</div>

  );
};

export default DynamicTextScale;
