import Logo from 'components/logo'
import React, { useEffect, useState } from 'react';

export default function TopDetail({ values, selectColor = 0,tab }) {
  const [isHidden, setIsHidden] = useState(false); // Logo'nun görünürlüğünü kontrol eden state
  useEffect(() => {
    // values.companyId kontrolü
    if (values.companyId === '6562742a5b252fdddcc588e7') {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [values.companyId]);
  const labelType = 
    tab === 0 ? values.washingLabel :
    tab === 1 ? values.warningLabel :
    tab === 2 ? values.priceLabel :
    tab === 3 ? values.beltLabel : 
    values.washingLabel; // Default olarak washingLabel alınıyor

                    
  console.log("valuessss",values.companyId);
  return (
    <div className="flex w-full  gap-x-2" style={{justifyContent:"center"}}>
      {!isHidden && <Logo className="h-28" />} {/* Eğer isHidden true değilse logo gösterilir */}
      <div className='dargo' >
      <div className=" space-y-1 text-xs font-light" style={{marginTop:"11px"}}>
        <div className=" bg-simsek  text-white margo"  style={{height:"25px",paddingLeft:"4px",paddingBottom:"4px"}}><span className='margo' style={{overflow:"visible"}}>Layout information</span></div>
        <div className="flex gap-x-2 margo ">
          <div className="space-x-1">
            <span className="font-semibold uppercase">Müşteri Adı:</span>
            <span>{values.company.name}</span>
          </div>
          <div className="space-x-1 ">
            <span className="font-semibold uppercase">Müşteri Sipariş No:</span>
            <span>{values.customerOrderNo}</span>
          </div>
        </div>
        <div className="flex gap-x-2 ">
          <div className="space-x-1">
            <span className="font-semibold uppercase">sezon:</span>
            <span>{values.season}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">marka Order No:</span>
            <span>{values.brandOrderNo}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">SupplIer No:</span>
            <span>{values.supplierNo}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">SipariŞ No:</span>
            <span>{values.orderNo}</span>
          </div>
        </div>
        <div className="flex gap-x-2 ">
          <div className="space-x-1">
            <span className="font-semibold uppercase">artIkel:</span>
            <span>{values?.articleNo}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">renk kodu:</span>
            <span>{labelType[selectColor].colorCode}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">made In:</span>
            <span>{values.country.name}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">Referans Kodu:</span>
            <span>
              {values.referance.code}-{values.referance.height}*{values.referance.width}
            </span>
          </div>
        </div>
        <div className="flex gap-x-2 ">
          <div className="space-x-1">
            <span className="font-semibold uppercase">Departman:</span>
            <span>{values?.department}</span>
          </div>
          <div className="space-x-1">
            <span className="font-semibold uppercase">SIze type:</span>
            <span>{values?.sizeGroup.name}</span>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
