import { useParams,useLocation  } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { FileDown, FileSignature } from 'lucide-react'
import { Link } from 'react-router-dom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { useArtworkOrder } from 'store/server/useOrder'
import useOrderStore from 'store/client/orderStore'
import html2pdf from 'html2pdf.js'
import { useCreatePDF } from 'store/server/useOrder'
import { Button } from 'components/ui/button'
import Spinner from 'components/spinner'
import L60_25 from './L60_25'
import L60_30 from './L60_30'
import domtoimage from 'dom-to-image';
import L60_40 from './L60_40'
import L120_25 from './L120_25'
import AllRoutes from 'Routes'
import Warning_55_120 from './Warning_55_120'
import L55XPR from './Price/index'
import BeltMain from '../ArtWork2/Belt/İndex'


export default function ArtWork2() {
  const { orderNo } = useParams();
  const location = useLocation();
  const [tab, setTab] = useState(null);
  const [selectColor, setSelectColor] = useState(0);
  const [pageStyle, setPageStyle] = useState('@page { size: portrait; }');
  const componentRef = useRef(null);
  
  const { data, isFetching } = useArtworkOrder(orderNo, tab);
  const { setReferance } = useOrderStore();

  const { mutate: createPDF, data: pdfUrl, isLoading, error } = useCreatePDF()





  useEffect(() => {
    // Veri henüz yüklenmediyse veya data.referance tanımlı değilse bir şey yapma
  
    // Referans bilgilerini kullan
    setReferance(data.referance);
  
    // Tab bilgisini URL'den çek
    const queryParams = new URLSearchParams(location.search);
    const newTab = parseInt(queryParams.get('tab'), 10);
    
    console.log("YENİİİ"+data);
    setTab(newTab);
  

  
  }, [data, location.search]);




  const changedata = (tab) => {
  
    const newPageStyle = ( tab === 3 || tab === 2) ? '@page { size: landscape; }' : '@page { size: portrait; }';
    setPageStyle(newPageStyle);
    console.log("burada")
  }
  if (isFetching) {
    return <Spinner className="h-screen w-5" />;
  }
  function applyMarginTopToElements(element) {
    // `.mt-1` sınıfını içeren tüm elementleri seç
    const elements = element.querySelectorAll('.mt-1')

    // Her bir element için marginTop stilini ayarla
    elements.forEach((el) => {
      el.style.marginTop = '0.25rem'
    })
  }

  async function exportPDF() {
    await document.fonts.ready;
  
    const element = componentRef.current;
    element.style.padding = '0';
    element.style.margin = '0';
    const orientation = labelWidth === 120 ? 'landscape' : 'portrait';
    const startYPosition = 0; // Yükseklik başlangıç noktası
  
    // Özel stilleri ekleyin
    const style = document.createElement('style');
    style.innerHTML = `
      .margo { margin-top: -2px !important; }
      .dargo { margin-top: -5px !important; }
      .bottor { margin-bottom: 4px !important; }
      .zargo { margin-top: -8px !important; }
      .kargo { margin-top: 4px !important; }
      .mard { margin-top: -2px !important; margin-left: -4px !important; }
    `;
    document.head.appendChild(style);
  
    // HTML elementini SVG'ye dönüştür
   /* domtoimage.toSvg(element)
      .then(function (dataUrl) {
        var svg = atob(dataUrl.substring('data:image/svg+xml;base64,'.length));
        var doc = new jsPDF({
          orientation: orientation,
          unit: 'mm',
          format: 'a4',
          compressPDF: true
        });
  
        doc.addSVG(svg, 0, 0, 210, 297); // SVG'yi PDF'e ekleyin
        doc.save('document.pdf'); // PDF dosyasını kaydedin
      })
      .catch(function (error) {
        console.error('SVG dönüşümünde hata oluştu', error);
      })
      .finally(() => {
        document.head.removeChild(style); // Özel stilleri kaldır
      });
      */
  }

  async function exportPDFWithMultiplePages() {
    const element = componentRef.current; // PDF'e dönüştürmek istediğiniz element
    const orientation = 'portrait'; // Veya 'landscape'
    const format = 'a4'; // Sayfa boyutu
    
    // jsPDF nesnesini oluştur
    const pdf = new jsPDF({
      orientation,
      unit: 'mm',
      format,
    });
  
    // İçeriği sayfa sayfa işleyin (Örnek olarak 1 sayfa gösterilmektedir, döngü ile sayfa sayısı artırılabilir)
    html2canvas(element, { scale: 2 }).then(canvas => {
      // Canvas'tan resim URL'si oluştur
      const imgData = canvas.toDataURL('image/png');
  
      // Resmi PDF'e ekle
      pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth()*2, pdf.internal.pageSize.getHeight()*2);
  
      // Eğer daha fazla sayfa varsa 'addPage' metodu ile yeni sayfa ekleyin ve işlemi tekrarlayın
      // pdf.addPage();
  
      // PDF'i kaydet
      pdf.save('document.pdf');
    });
  }
  const handleCreatePDF = () => {
    if (componentRef.current) {
      const htmlContent = componentRef.current.outerHTML
      const pdfData = { content: htmlContent, orientation: 'portrait' } // PDF verisi
      createPDF(pdfData) // PDF oluşturma işlemini tetikle
    }
  }
  
  function TabContent({ data, selectColor, componentRef }) {
    const labelWidth = data.referance.width;
    const labelHeight = data.referance.height;

    if (labelWidth === 60 && labelHeight === 25) {
      return <L60_25 data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;
    }
    if (labelWidth === 60 && labelHeight === 30) {
      return <L60_30 data={data} selectColor={selectColor} ref={componentRef} tab={tab}/>;
    }
    if (labelWidth === 60 && labelHeight === 40) {
      return <L60_40 data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;
    }
    if (labelWidth === 120 && labelHeight === 25) {
      return <L120_25 data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;
    }
    // Diğer durumlar için varsayılan bir içerik göster
    return <div>Bu boyuttaki etiketin tasarımı henüz hazır değil.</div>;
  }

  function TabContent2({ data, selectColor, componentRef }) {

    const labelWidth = data.referance.width;
    const labelHeight = data.referance.height;

    return <L55XPR data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;

  }

  function TabContent3({ data, selectColor, componentRef }) {

    const labelWidth = data.referance.width;
    const labelHeight = data.referance.height;

    return <BeltMain data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;

  }

  function TabContent1({ data, selectColor, componentRef }) {
    const labelWidth = data.referance.width;
    const labelHeight = data.referance.height;
    return <Warning_55_120 data={data} selectColor={selectColor} ref={componentRef} tab={tab} />;

  }

  if (isFetching) return <Spinner className="h-screen w-5" />
  const labelHeight = data.referance.height
  const labelWidth = data.referance.width

  

  const department = data.brand.Department.find(
    (item) => item.name === data.department.split('/')[1].trim()
  )
  return (
    <div className="relative py-20 bg-gray-200">

      <div className="absolute left-1/2 -translate-x-1/2 top-5 flex gap-x-2">
  

          {tab === 0 && data.washingLabel.length > 1 &&
    data.washingLabel.map((item, idx) => (
      <Button key={idx} variant="blue" size="sm" onClick={() => setSelectColor(idx)}>
        {idx + 1}.Renk ({item.colorCode})
      </Button>
    ))}
  {tab === 1 && data.warningLabel && data.warningLabel.length > 1 &&
    data.warningLabel.map((item, idx) => (
      <Button key={idx} variant="blue" size="sm" onClick={() => setSelectColor(idx)}>
        {idx + 1}.Renk ({item.colorCode})
      </Button>
    ))}
    {tab === 2 && data.priceLabel && data.priceLabel.length > 1 &&
    data.priceLabel.map((item, idx) => (
      <Button key={idx} variant="blue" size="sm" onClick={() => setSelectColor(idx)}>
        {idx + 1}.Renk ({item.colorCode})
      </Button>
    ))}
    {tab === 3 && data.beltLabel && data.beltLabel.length > 1 &&
    data.beltLabel.map((item, idx) => (
      <Button key={idx} variant="blue" size="sm" onClick={() => setSelectColor(idx)}>
        {idx + 1}.Renk ({item.colorCode})
      </Button>
    ))}
      </div>
      <div className="absolute top-5 right-5 flex gap-x-2">
        <Link
          to={`/siparisler/ekle/${data.brand.id}_${data.brand.name}/${department.id}_${
            department.name
          }/${data.department.split('/')[2].trim()}/${orderNo}`}
        >
          <Button size="sm">
            <FileSignature size={20} className="mr-2" />
            Düzenle
          </Button>
        </Link>
        <div style={{display:'none'}}>    <Button variant="green" className="flex gap-x-2" size="sm" onClick={exportPDF}>
          <FileSignature size={20} className="mr-2" />
          PDF Olarak İndir
        </Button></div>
    
        <ReactToPrint
          trigger={() => (
            <Button variant="green" className="flex gap-x-2" size="sm" onClick={changedata(tab)}>
              <FileDown size={20} /> Pdf olarak kaydet(eski)
            </Button>
          )}
          content={() => componentRef.current}
          pageStyle={pageStyle}

        />
        {isLoading && <p>PDF Oluşturuluyor...</p>}
        {error && <p>Hata: {error.message}</p>}
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '500px' }}
            title="PDF Preview"
          ></iframe>
        )}
      </div>
      {tab === 0 && (
        <TabContent data={data} selectColor={selectColor} componentRef={componentRef}/>
      )}
      {tab === 1 && (
        <TabContent1 data={data} selectColor={selectColor} componentRef={componentRef} />
      )}
      {tab === 2&& (
        <TabContent2 data={data} selectColor={selectColor} componentRef={componentRef} />
      )}
      {tab === 3&& (
        <TabContent3 data={data} selectColor={selectColor} componentRef={componentRef} />
      )}
    </div>
  )
}
