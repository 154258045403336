import React from 'react';
import { Modal } from 'antd';

const DisliSayisiDetayModal = ({
  visible,
  onCancel,
  title = 'Referans Numarası Ekle',
  children,
  minWidth = "30%", // Varsayılan minWidth değeri
  width = 500, // Varsayılan width değeri
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={width} // Modal genişliği için
      style={{ minWidth }} // minWidth props'u style ile kullanılır
    >
      {children}
    </Modal>
  );
};

export default DisliSayisiDetayModal;
