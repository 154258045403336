import React, { useEffect, useState } from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import Page1 from './Page1'
import PageKall from './PageKall'
import PageKallLittle from './PageKallLittle'
import { ReactComponent as Zaraön } from '../../../../../assets/Varlık2.svg'
import useOrderStore from 'store/client/orderStore'
import { IoTriangleOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import { ReactComponent as ZaraBom } from '../../../../../assets/DD.svg'
import { ReactComponent as ZaraBomRed } from '../../../../../assets/DDRED.svg'
import { ReactComponent as AUDDOLAR } from '../../../../../assets/DDRED.svg'
import { ReactComponent as ZaraAcc } from '../../../../../assets/zaraaccess01.svg'
import { ReactComponent as ZaraAccLinge } from '../../../../../assets/linnngee.svg'
import { ReactComponent as ZaraAccEmty } from '../../../../../assets/accesemty.svg'
import { ReactComponent as ZaraAccLin } from '../../../../../assets/accesslin.svg'
import { ReactComponent as CardFront } from '../../../../../assets/waist/waistback.svg';
import { ReactComponent as CardFront3292 } from '../../../../../assets/waist/3292backwaist.svg';
import { ReactComponent as WTZCAL0 } from '../../../../../assets/waist/WTZCALL002.svg';


import { ReactComponent as CardDeneme } from '../../../../../assets/waist/waistemty.svg';
import { ReactComponent as CardTry1 } from '../../../../../assets/cleanedtry.svg';



const loadSVG = async (code) => {
  try {
    console.log(`[loadSVG] Gelen kod: ${code}`);
    const module = await import(`../../../../../assets/waist/${code}.svg`);
    console.log(`[loadSVG] İçe aktarılan module:`, module);

    // ReactComponent veya default'u döndür
    return module.ReactComponent || module.default || null;
  } catch (error) {
    console.error(`[loadSVG] SVG yüklenemedi. Kod: ${code}, Hata:`, error);
    return null;
  }
};
const renderDiv = (referenceCode, referenceWidth) => {
  let style = {
    width: "50.2mm",
    height: "102mm",
    position: "absolute",
    top: 0.4,
    left: -0.4,
    zIndex: 36,
    opacity: "1",
    border: "1.2px solid #898A8D",
  };

  if (referenceCode.includes("CALL") && referenceWidth === 50) {
    style.width = "50.2mm";
    style.height = "102.2mm";
  } else if (referenceCode.includes("KALL") && referenceWidth === 50) {
    style.width = "50.2mm";
    style.height = "102.2mm";
  } else if (referenceCode.includes("KALL") && referenceWidth === 32) {
    style.width = "32.2mm";
    style.height = "92.1mm";
    style.top=0;  
  }

  return (
    <div style={style}>
      <div style={{ width: style.width, height: style.height, border: style.border }}></div>
    </div>
  );
};

const CardComponent = ({ referenceCode, referenceWidth,data }) => {
  if (referenceCode.includes("KALL") && referenceWidth === 50) {
    return (
      <div
        style={{
          width: "50.2mm",
          height: "102.2mm",
          border: "1px solid transparent",
          position: "absolute",
          top: 0,
          left: 5,
        }}
      >
      
      <div style={{ position: 'absolute', bottom:11.5, right: 12.80,letterSpacing:"0.15px" }}>
          <span className='helve-roman' style={{fontSize:"2.96px",color:"#c4c4c6",fontWeight:300}}>
          {data.company?.fsc}
          </span>
          </div>

      <div style={{width:"50.2mm",height:"102.2mm",border:"1px solid #898A8D",position:"absolute",top:0,left:5}}></div>
        <CardFront width="52.9mm" height="102.1mm" />
      </div>
    );
  } else if (referenceCode.includes("KALL") && referenceWidth === 32) {
    return (
      <div
        style={{
          width: "32.2mm",
          height: "92.2mm",
          border: "1px solid transparent",
          position: "absolute",
          top: 0,
          left: 5,
        }}
      >
         <div style={{ position: 'absolute', bottom:56.5, right: 26.50,letterSpacing:"0.15px" }}>
          <span className='helve-roman' style={{fontSize:"2.96px",color:"#404040",fontWeight:300}}>
          {data.company?.fsc}
          </span>
          </div>

        <CardFront3292 width="32.9mm" height="92.1mm" />
      </div>
    );
  } else if (referenceCode.includes("CALL") && referenceWidth === 50) {
    return (
      <div
        style={{
          width: "50.2mm",
          height: "102.2mm",
          border: "1px solid transparent",
          position: "absolute",
          top: 0,
          left: 5,
        }}
      >
      
      <div style={{ position: 'absolute', bottom:11.5, right: 12.80,letterSpacing:"0.15px" }}>
          <span className='helve-roman' style={{fontSize:"2.96px",color:"#c4c4c6",fontWeight:300}}>
          {data.company?.fsc}
          </span>
          </div>

      <div style={{width:"50.2mm",height:"102.2mm",border:"1px solid #898A8D",position:"absolute",top:0,left:5}}></div>
        <CardFront width="52.9mm" height="102.1mm" />
      </div>
      
    );
  } else {
    return null; // Eğer hiçbir koşul sağlanmazsa boş döndür
  }
};
const BeltMain = ({ data, selectColor, tab }, ref) => {
  const department = data.department
  const [brand, category, subCategory] = department.split(' / ')
  const [SouthAmerica, setSouthAmerica] = useState(false)
  const [loading, setLoading] = useState(true);
  const [CardBackBack, setCardBackBack] = useState(null);
  const [shouldRender, setShouldRender] = useState(false);
  const [RefCode, setRefCode] = useState('');
  const [RefWidth, setRefWidth] = useState(0);
  const [hasRefreshed, setHasRefreshed] = useState(false);


  const { referance } = useOrderStore()

  const [harmacn, setHarmacn] = useState(false) // harmacn state'i

  

  useEffect(() => {
    console.log("[useEffect - referance] referance değişti:", referance);

    if (referance?.code) {
      console.log("[useEffect - referance] Yükleniyor: ", referance.code);
      setRefCode(referance?.code)
      setRefWidth(referance?.width)
      loadSVG(referance.code)
        .then((component) => {
          console.log("[useEffect - referance] Yüklenen SVG Component:", component);
          setCardBackBack(() => component);
        })
        .catch((error) => {
          console.error("[useEffect - referance] SVG yüklenirken hata:", error);
        });
    }
  }, [referance]);

  useEffect(() => {
    if (referance) {
      setShouldRender(true);
 
    }
  }, [referance]);


  const isBlack = referance?.backgroundColor === 'B'


  

  // item.amount > 0 koşulunu sağlayan öğeleri filtrele ve her 8 öğede bir grupla
  const filteredAndGroupedSizes = data.beltLabel[selectColor].sizes
    .filter((item) => item.amount > 0)
    .reduce((acc, item, index) => {
      const groupIndex = Math.floor(index / 4) // Her 3 öğe için bir grup indeksi
      if (!acc[groupIndex]) {
        acc[groupIndex] = { items: [], startIndex: index - (index % 4) } // Yeni bir grup oluştur
      }
      acc[groupIndex].items.push(item)
      return acc
    }, [])

  return (
    
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ref={ref}>
    
      {filteredAndGroupedSizes.map((groupObj, groupIndex) => (
        <A4Page key={groupIndex} type="yatay">
          <div className="space-y-5" style={{ position: 'relative' }}>
 
              <div
  style={{
    position: 'absolute',
    top: '135px',
    left: `50px`,
  }}
>
<CardComponent referenceCode={RefCode} referenceWidth={RefWidth} data={data} />

</div>
            <TopDetail values={data} selectColor={selectColor} tab={tab} />
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 22 }}>
            <div
                  className="printable"
                  style={{
                    position: 'relative',
                    marginRight: 50,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 104
                  }}
                >
                </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginRight: 60,
                }}
              >
         
         
              </div>
              {groupObj.items.map((sizeData, index) => (
                <div>
       
            
                    <div
                      style={{
   
                        position: 'absolute',
                        top: '135px',
                        right: `${670 - index * 210}px`,
                      }}
                    >
  <div>
      {RefCode ? (
        (() => {
          const referenceCode = RefCode;
          const referenceWidth = RefWidth;

          if (referenceCode.includes('KALL') && referenceWidth === 50) {
            return (
              <div  className="printable" style={{ position: 'relative', width: "50mm", height: "102.2mm" }}>
              <PageKall
                key={groupObj.startIndex + index}
                selectColor={selectColor}
                barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.beltLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                data={data}
                referance={data?.reference}
                SouthAmerica={SouthAmerica}
                sizeData={sizeData}
                indo={data?.beltLabel[0]?.sizes}
                szrgrp={data?.sizeGroup}
                inx={groupObj.startIndex + index}
              />
              </div>
            );
          } else if (referenceCode.includes('KALL') && referenceWidth === 32) {
            return (
              <div  className="printable" style={{ position: 'relative', width: "32.2mm", height: "92.2mm" }}>

              <PageKallLittle
                key={groupObj.startIndex + index}
                selectColor={selectColor}
                barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.beltLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                data={data}
                referance={data?.reference}
                SouthAmerica={SouthAmerica}
                sizeData={sizeData}
                indo={data?.beltLabel[0]?.sizes}
                szrgrp={data?.sizeGroup}
                inx={groupObj.startIndex + index}
              />
                </div>
            );
          } else if (referenceCode.includes('CALL') && referenceWidth === 50) {
            return (
              <div  className="printable" style={{ position: 'relative', width: "50mm", height: "102.2mm" }}>

              <Page1
                key={groupObj.startIndex + index}
                selectColor={selectColor}
                barcodeValue={`0${data?.articleNo?.split('/')[0]}${data?.articleNo?.split('/')[1]}${data?.beltLabel[selectColor]?.colorCode}${sizeData.sCode ? sizeData.sCode : ''}`}
                data={data}
                referance={data?.reference}
                SouthAmerica={SouthAmerica}
                sizeData={sizeData}
                indo={data?.beltLabel[0]?.sizes}
                szrgrp={data?.sizeGroup}
                inx={groupObj.startIndex + index}
              />
               </div>
            );
          } else {
            return null;
          }
        })()
      ) : (
        <span>Loading...</span>
      )}
    </div>
<div style={{position:"absolute",top:0,left:0}}>

{CardBackBack ? (
    (() => {
      const referenceCode = RefCode;
          const referenceWidth = RefWidth;

      if (referenceCode.includes("KALL") && referenceWidth === 50) {
        <></>
   
      } else if (referenceCode.includes("KALL") && referenceWidth === 32) {
        <></>
      } else if (referenceCode.includes("CALL") && referenceWidth === 50) {
    
        <></>
   
      } else {
        return null;
      }
    })()
  ) : (
    <></>
  )}

  {renderDiv(RefCode, RefWidth)}
</div>
                
                    </div>
             



                </div>
              ))}
            </div>
          </div>
        </A4Page>
      ))}
    </div>
  )
}

export default React.forwardRef(BeltMain)
