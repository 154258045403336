import React from 'react'
import A4Page from '../A4Page'
import TopDetail from '../TopDetail'
import SectionOne from './SectionOne'
import { ReactComponent as WhiteFront } from '../../../../../assets/whitefrontalwarning.svg';
import { ReactComponent as WhiteBack } from '../../../../../assets/whitebackwarning.svg';
import { ReactComponent as BlackFront } from '../../../../../assets/blackfrontalwarning.svg';
import { ReactComponent as BlackBack } from '../../../../../assets/blackbackwarning.svg';



import useOrderStore from 'store/client/orderStore'
import { WidthIcon } from '@radix-ui/react-icons';

const Warning_55_120 = ({ data, selectColor,tab }, ref) => {
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  console.log("BURADAAA",referance);
  return (
    <div className="flex flex-col items-center" ref={ref}>
      <A4Page type="dikey">
        <div className="space-y-25">
          <TopDetail values={data} selectColor={selectColor} tab={tab} />
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
          <div style={{width:"55mm",height:"120mm",marginTop:15}}>
            {isBlack ? <BlackFront /> : <WhiteFront />}
          </div>
          <div style={{width:"55mm",height:"120mm",marginTop:15,marginLeft:20}}>
            {isBlack ? <BlackBack /> : <WhiteBack />}
          </div>
          </div>
        </div>
      </A4Page>
    </div>
  )
}

export default React.forwardRef(Warning_55_120)
