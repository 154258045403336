import { FieldArray, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { RotateCcw } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import requests from 'service/api'
import { useOrderPriceSave, useOrderPriceUpdate } from 'store/server/useOrder'
import DisliSayisiDetayModal from 'components/form/modals/DisliSayisiDetayModal'

import { Input } from 'components/form/input'
import { Input as NoFormInput } from 'components/ui/input'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'
import { SelectReferanceCode3 } from 'components/form/compobox/select-referance-code3'
import { toast } from 'components/ui/use-toast'
import PriceSizes from '../PriceInstructions/PriceSize'
import PriceSection from '../PriceInstructions/PriceSection'
import { Form, Input as AntInput, Button as AntButton, Select, message, Modal, Table,Tooltip  } from 'antd'

import { RxCross1 } from "react-icons/rx";
import { Link } from 'react-router-dom'
import { X } from 'lucide-react'
const { Option } = Select

export default function PriceInstructions({ currentTab }) {
  const { values, setFieldValue } = useFormikContext()
  const { mutate: saveOrder, isPending } = useOrderPriceSave()
  const { mutate: updateOrder, isPending: isPendingUpdate } = useOrderPriceUpdate()
  const { brand, orderNo, dept, subDept } = useParams()
  const brandId = brand.split('_')[0]
  const [isDisliModalVisible, setIsDisliModalVisible] = useState(false)

  const handleSaveOrUpdateOrder = () => {
    // Hata mesajlarını saklamak için bir dizi
    const errors = [];
  
    // currency1 ve currency2 için boş olup olmadığını kontrol et
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 alanı boş olamaz.`);
      }
      if (!item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 2 alanı boş olamaz.`);
      }
    });
  
    // Hata varsa, işlemi durdur ve hataları göster
    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      alert(errors.join('\n'));
      return; // İşlemi durdur
    }
  }



  const handleDisliModalClose = () => {
    setIsDisliModalVisible(false)
  }

  const handleDisliModalOpen = () => {
    setIsDisliModalVisible(true)
  }
  const ClearableSelect = ({ value, onChange, options, placeholder, form, name }) => {
    const handleClear = () => {
      // Seçili değeri sıfırla
      form.setFieldsValue({ [name]: undefined });
      onChange(undefined);
    };
  
    return (
      <div style={{ position: "relative" }}>
  
        <Select
          showSearch
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
          suffixIcon={<span style={{ display: 'none' }}></span>} // Arama ikonunu gizler
  
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {value && (
          <RxCross1
            onClick={handleClear}
            style={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "red",
            }}
          />
        )}
      </div>
    );
  };
  const DrawerContent4 = () => {
    const [form4] = Form.useForm()
    const [GrafDurum, setGrafDurum] = useState([]) // Tablo verileri için state
    const [GrafDurumOld, setGrafDurumOld] = useState([]) // Tablo verileri için state
    const [searchText, setSearchText] = useState('') // Tablo verileri için state
    const [isRowModalVisible, setIsRowModalVisible] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // Seçilen satırdaki veri
    const [colordsx, setcolordsx] = useState([
      { code: "W", text: " WHITE" },
      { code: "B", text: "BLACK" },
    ]);

    // React Query ile veri çekimi
    const { data, isFetched, refetch } = useQuery({
      queryKey: ['/list', 'referance'],
      queryFn: async () => {
        const { data } = await requests.referance.list()

        return data
      },
      placeholderData: []
    })

    // useEffect ile her girişte veri yeniden çekilsin
    useEffect(() => {
      refetch().then((response) => {
        console.log('Refetched Data:', response.data) // Gelen veriyi konsola yazdır
        const isWasgingLabel = response.data.filter(
          (item) => item.labelCategory === 'FİYAT KARTI'
        )

        setGrafDurum(isWasgingLabel)
        setGrafDurumOld(isWasgingLabel)
      })
    }, [refetch])

    // Uyarı etiketleri için filtreleme

    const onSipNoFinishGrafik = (values) => {
      const requiredFields = ['code', 'name', 'height','width', 'kod', 'a', 'backgroundColor'];
      const emptyFields = requiredFields.filter((field) => !values[field]);

      // Eğer boş alan varsa hata döndür
      if (emptyFields.length > 0) {
        const fieldNames = {
          code: 'Kod',
          name: 'İsim',
          height: 'Yükseklik',
          width:'Genişlik',
          kod: 'Kod',
          a: 'A Değeri',
          backgroundColor: 'Arkaplan Rengi',
        };
        const missingFields = emptyFields.map((field) => fieldNames[field]).join(', ');
        message.error(`Lütfen şu alanları doldurun: ${missingFields}`);
        return; // Devam etme
      }
      
      Modal.confirm({
        title: 'Referans eklemek istiyor musunuz?',
        content: 'Evet dediğinizde Referans bilgileri eklenecektir.',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk: async () => {
          try {
            const response = await requests.order.addReferenceCode({
              code: values.code,
              name: values.name,
              height: values.height,
              width:values.width,
              subDepartmentId: "6575fe53f5358ec4a7a0d0b8",
              kod: values.kod,
              labelCategory: "FİYAT KARTI",
              labelType: "FİYAT KARTI",
              a: values.a,
              backgroundColor: values.backgroundColor,
              shape: '',
              shape2: ''
            })

            if (response.status === 200) {
              Modal.success({
                title: 'Başarılı',
                content: 'Sipariş başarıyla güncellendi.'
              })
              console.log("dataşöylegelid",response.data)
              setGrafDurum(response.data.data) // Yeni veriyi tabloya ekle
              setGrafDurumOld(response.data.data) // Yeni veriyi tabloya ekle
              refetch(); // Refetch'i tetikle
            }

          } catch (error) {
            console.error('Hata oluştu:', error)
            Modal.error({
              title: 'Hata',
              content: 'Sipariş güncellenirken bir hata oluştu.'
            })
          }
        },
        onCancel() {
          console.log('Güncelleme iptal edildi.')
        }
      })
    }
    const handleReferanceSil = (id) => {
      Modal.confirm({
        title: 'Referansı silmek güncellemek istiyor musunuz?',
        content: 'Referans silindiğinde geri alınmayacaktır.',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk: async () => {
          try {
            const response = await requests.order.deleteReferenceCode({
              id: id,
            })

            if (response.status === 200) {
              Modal.success({
                title: 'Başarılı',
                content: 'Sipariş başarıyla güncellendi.'
              })

              setGrafDurum(response.data.data) // Yeni veriyi tabloya ekle
              setGrafDurumOld(response.data.data) // Yeni veriyi tabloya ekle
              refetch(); // Refetch'i tetikle

            }
          } catch (error) {
            console.error('Hata oluştu:', error)
            Modal.error({
              title: 'Hata',
              content: 'Sipariş güncellenirken bir hata oluştu.'
            })
          }
        },
        onCancel() {
          console.log('Güncelleme iptal edildi.')
        }
      })
    };
  
    const handleSearchmini = (value) => {
      console.log("valueeeee",value)
      setSearchText(value);
      const filtered = GrafDurum.filter((item) => {
        return (
          (item.code && item.code.includes(value)) || // code zaten string
          (item.height && String(item.height).includes(value)) || // height string'e dönüştürülüyor
          (item.width && String(item.width).includes(value)) || // width string'e dönüştürülüyor
          (item.name && item.name.includes(value)) // name zaten string
        );
      });
      setGrafDurum(value ? filtered : GrafDurumOld);
      
  
    };
    const minicolumnsgrafikdurum = [
      {
        title: 'Kod',
        dataIndex: 'code',
        key: 'code',
        render: text => <span style={{ fontSize: '12px' }}>{text}</span>

      },
      {
        title: 'İsim',
        dataIndex: 'name',
        key: 'name',
        render: (text) => (
          <Tooltip title={text}>
            <span style={{ fontSize: '12px' }}>
              {text.length > 20 ? `${text.substring(0, 20)}...` : text}
            </span>
          </Tooltip>
        ),

      },
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        render: text => <span style={{ fontSize: '12px' }}>{text}</span>

      },
      {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
        render: text => <span style={{ fontSize: '12px' }}>{text}</span>

      },
      {
        title: 'Renk',
        dataIndex: 'backgroundColor',
        key: 'backgroundColor',
        render: text => <span style={{ fontSize: '12px' }}>{text}</span>

      },
      {
        title: 'Açıklamakod',
        dataIndex: 'kod',
        key: 'kod',
        render: text => <span style={{ fontSize: '12px' }}>{text}</span>

      }, // Fazladan virgül bu satırdan kaldırıldı
      {
        title: 'İşlemler',
        key: 'actions',
        render: (text, record) => (
          <div
          onClick={(e) => {
        e.stopPropagation(); // Tıklama olayını üst katmanlara taşımayı engeller
        handleReferanceSil(record.id); // Silme işlemini tetikler
      }}
          style={{
            cursor: 'pointer', // Tıklanabilir hissiyatı verir
            padding: '5px 10px',
            backgroundColor: 'red',
            color: 'white',
            border: '1px solid red',
            borderRadius: '4px',
            display: 'inline-block',
            textAlign: 'center',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#cc0000'; // Hover rengi
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'red'; // Orijinal renk
          }}
        >
          Referans Sil
        </div>
        ),
      },
    ];
    const handleRowGrafDurumClick = (record) => {
      setSelectedRowData(record); // Satırdaki veriyi state'e aktar
      console.log("recoo",record);
      setIsRowModalVisible(true); // Modalı aç
    };

    const handleRowModalSave = () => {
      // Kaydetme işlemleri burada yapılır
      console.log("Kaydedilen Veri:", selectedRowData);
 
      const requiredFields = ['code', 'name', 'height','width', 'kod', 'a', 'backgroundColor'];
      const emptyFields = requiredFields.filter((field) => !selectedRowData[field]);

      // Eğer boş alan varsa hata döndür
      if (emptyFields.length > 0) {
        const fieldNames = {
          code: 'Kod',
          name: 'İsim',
          height: 'Yükseklik',
          width:'Genişlik',
          kod: 'Kod',
          a: 'A Değeri',
          backgroundColor: 'Arkaplan Rengi',
        };
        const missingFields = emptyFields.map((field) => fieldNames[field]).join(', ');
        //message.error(`Lütfen şu alanları doldurun: ${missingFields}`);
        return; // Devam etme
      }
      Modal.confirm({
        title: 'Referans durumunu güncellemek istiyor musunuz?',
        content: 'Evet dediğinizde Referans bilgileri güncellenecektir.',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk: async () => {
          try {
            const response = await requests.order.updateReferenceCode({
              id:selectedRowData.id,
              code: selectedRowData.code,
              name: selectedRowData.name,
              height: selectedRowData.height,
              width:selectedRowData.width,
              subDepartmentId: "6575fe53f5358ec4a7a0d0b8",
              kod: selectedRowData.kod,
              labelCategory: "FİYAT KARTI",
              labelType: "FİYAT KARTI",
              a: selectedRowData.a,
              backgroundColor: selectedRowData.backgroundColor,
              shape: '',
              shape2: ''
            })

            if (response.status === 200) {
              Modal.success({
                title: 'Başarılı',
                content: 'Sipariş başarıyla güncellendi.'
              })
              console.log("responseee",response.data.data);
              setGrafDurum(response.data.data) // Yeni veriyi tabloya ekle
              setGrafDurumOld(response.data.data) // Yeni veriyi tabloya ekle
              refetch(); // Refetch'i tetikle

            }
          } catch (error) {
            console.error('Hata oluştu:', error)
            Modal.error({
              title: 'Hata',
              content: 'Sipariş güncellenirken bir hata oluştu.'
            })
          }
        },
        onCancel() {
          console.log('Güncelleme iptal edildi.')
        }
      })
      // Örneğin, updateOrder fonksiyonuna gönderilebilir:

    
      setIsRowModalVisible(false); // Modalı kapat
    };
    
    const handleRowModalChange = (key, value) => {
      setSelectedRowData((prev) => ({ ...prev, [key]: value })); // Seçilen veriyi güncelle
    };
    return (
      <div>
        <Form
          form={form4}
          layout="horizontal"
          onFinish={onSipNoFinishGrafik}
          initialValues={{ radio: '1' }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            position: 'relative',
            flexShrink: 1,
            boxSizing: 'border-box',
            overflow: 'hidden',
            width: 700
          }}
          labelAlign="left"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '16px',
              width: '100%'
            }}
          >
            <Form.Item label="Kod:" name="code" style={{ width:"100%",margin:0,padding:0 }}>
              <AntInput placeholder="Kod girin" />
            </Form.Item>
            <Form.Item label="İsim:" name="name" style={{ width:"100%",margin:0,padding:0  }}>
              <AntInput placeholder="İsim" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "50%",
      boxSizing: "border-box", // Eklenen box-sizing
      paddingTop:10,
    }}
  >
    <Form.Item label="Width:" name="width" style={{ width: "100%", margin: 0 }}>
      <AntInput placeholder="Width girin" />
    </Form.Item>
    <Form.Item label="Height:" name="height" style={{ width: "100%", marginTop: "10px",marginBottom:0}}>
      <AntInput placeholder="Height girin" />
    </Form.Item>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "50%",
      boxSizing: "border-box", // Eklenen box-sizing
      paddingTop:10,

      paddingLeft:10
    }}
  >
    
              <Form.Item label="Renk" name="backgroundColor" style={{margin:0,width:"100%"}}>
            <ClearableSelect
              value={form4.getFieldValue('backgroundColor') || ''}
              onChange={(value) => form4.setFieldsValue({ backgroundColor: value })}
              options={colordsx.map((ilk) => ({
                value: ilk.code,
                label: ilk.text,
              }))}
              placeholder="Renk Seçin"
              form={form4}
              name="backgroundColor"
            />
          </Form.Item>
    <Form.Item label="A:" name="a" style={{ width: "100%", marginTop: "10px",marginBottom:0 }}>
      <AntInput placeholder="A" />
    </Form.Item>
  </div>

</div>

          <Form.Item label="Uzunkod:" name="kod" style={{ width: "50%", marginTop: "10px",marginBottom:10 }}>
      <AntInput placeholder="kod" />
    </Form.Item>

    <Form.Item>
            <AntButton
              htmlType="submit"
              style={{
                width: '100%',
                marginTop: 10,
                backgroundColor: 'green',
                color: 'white',
                paddingTop: 15,
                paddingBottom: 15,
                fontSize: 15
              }}
            >
              Kaydet
            </AntButton>
          </Form.Item>
  

          <div style={{ width: '100%' }}>
          <div style={{ width: '100%',display:"flex",flexDirection:"row",justifyContent:"flex-end" }}>
          <Form.Item>
          <AntInput
  placeholder="Ara"
  value={searchText}
  onChange={(e) => handleSearchmini(e.target.value)}
  onSearch={(value) => handleSearchmini(value)} // `onSearch` direkt `handleSearchmini` fonksiyonunu alabilir
  style={{ marginBottom: '0',width:"100%"}}
/>
</Form.Item>
</div>

            <Table
              columns={minicolumnsgrafikdurum}
              dataSource={GrafDurum} // Tablo verisi GrafDurum state'inden gelir
              rowKey="code"
              pagination={{ pageSize: 4 }}
              onRow={(record) => ({
                onClick: () => handleRowGrafDurumClick(record),

              })}
              components={{
    body: {
      row: (props) => (
        <tr {...props} style={{ height: '30px' }} /> // Satır yüksekliği burada ayarlanır
      ),
    },
  }}
            />
<Modal
  title="Referans Güncelle"
  visible={isRowModalVisible}
  onOk={handleRowModalSave}
  onCancel={() => setIsRowModalVisible(false)}
  okText="Güncelle" // "OK" yerine "Güncelle"
  cancelText="İptal" // "Cancel" yerine "İptal"
>
<Form 
  layout="inline" // Daha iyi düzen için "vertical"
  style={{ gap: '10px' }} // Form genel boşluk
>
  {/* Kod ve İsim Alanları Yan Yana */}
  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
    <Form.Item
      label="Kod"
      style={{ width:"50%" }}
    >
      <AntInput
        value={selectedRowData?.code || ""}
        onChange={(e) => handleRowModalChange("code", e.target.value)}
      />
    </Form.Item>
    <Form.Item
      label="İsim"
      style={{ width:"50%" }}
    >
      <AntInput
        value={selectedRowData?.name || ""}
        onChange={(e) => handleRowModalChange("name", e.target.value)}
      />
    </Form.Item>
  </div>

  {/* Genişlik ve Yükseklik Alanları Yan Yana */}
  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
    <Form.Item
      label="Genişlik"

      style={{ width:"50%" }}
    >
      <AntInput
        value={selectedRowData?.width || ""}
        onChange={(e) => handleRowModalChange("width", e.target.value)}
      />
    </Form.Item>
    <Form.Item
      label="Yükseklik"

      style={{ width:"50%" }}
    >
      <AntInput
        value={selectedRowData?.height || ""}
        onChange={(e) => handleRowModalChange("height", e.target.value)}
      />
    </Form.Item>
  </div>

  {/* Renk ve A Alanları Yan Yana */}
  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
    <Form.Item
      label="Renk"

      style={{ width:"50%" }}
    >
      <ClearableSelect
        value={selectedRowData?.backgroundColor || ''}
        onChange={(value) => handleRowModalChange("backgroundColor", value)}
        options={colordsx.map((ilk) => ({
          value: ilk.code,
          label: ilk.text,
        }))}
        placeholder="Renk Seçin"
      />
    </Form.Item>
    <Form.Item
      label="A"

      style={{ width:"50%" }}
    >
      <AntInput
        value={selectedRowData?.a || ""}
        onChange={(e) => handleRowModalChange("a", e.target.value)}
      />
    </Form.Item>
  </div>

  {/* Uzunkod */}
  <Form.Item 
    label="Uzunkod" 
    style={{ width:"100%" }}
  >
    <AntInput
      value={selectedRowData?.kod || ""}
      onChange={(e) => handleRowModalChange("kod", e.target.value)}
    />
  </Form.Item>
</Form>

</Modal>

          </div>
       
        </Form>
      </div>
    )
  }
  const handleSaveOrder = () => {
    const errors = [];
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency && !item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 ve Currency 2 alanlarının en az biri seçili olmalıdır.`);
      }
    });
    if (!values.priceLabel[0]?.colorCode) {
      toast.error("Renk Kodu Girilmek Zorunda")
      return
    }
    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      toast.error("Renk Kodu Girilmek zorunda")
      return; // İşlemi durdur
    }
    const department = `${brand.split('_')[1]} / ${dept.split('_')[1]} / ${subDept}`
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    const path = `${brand}/${dept}/${subDept}`
    saveOrder({ ...values, washingLabel: filteredValues, brand: brandId, department, path })
  }

  const hasDuplicateFabricTypes = () => {
    return values.washingLabel.some((label) => {
      const fabricTypes = label.fabric.flatMap((fabric) => fabric.mix.map((mix) => mix.type))
      const uniqueFabricTypes = new Set(fabricTypes)
      return uniqueFabricTypes.size !== fabricTypes.length
    })
  }

  const handleUpdateOrder = () => {
    const errors = [];
    values.priceLabel.forEach((item, index) => {
      if (!item.mix[0]?.currency && !item.mix[1]?.currency) {
        errors.push(`Price Label ${index + 1}: Currency 1 ve Currency 2 alanlarının en az biri seçili olmalıdır.`);
      }
    });
    if (errors.length > 0) {
      // Hataları gösterme şeklinizi buraya yazın. Örneğin:
      return; // İşlemi durdur
    }
    console.log("color codee",values.priceLabel[0]?.colorCode)
    if (!values.priceLabel[0]?.colorCode) {
      toast.error("Renk Kodu Girilmek Zorunda")
      return
    }
    // Tekrar eden kumaş türü yoksa, güncelleme işlemini gerçekleştir
    const filteredValues = values.washingLabel.map((label) => {
      const filteredFabric = label.fabric.map((fabricItem) => {
        const filteredMix = fabricItem.mix.filter((mix) => mix.percent > 0)
        return { ...fabricItem, mix: filteredMix }
      })

      return { ...label, fabric: filteredFabric }
    })
    delete values.brandId
    updateOrder({ id: orderNo, body: { ...values, washingLabel: filteredValues, brand: brandId } })
  }

  return (
    <FieldArray name="priceLabel">
      {({ push, remove }) => (
        <div>
          {values.priceLabel.map((_, idx) => (
            <div className="grid grid-cols-2 gap-5 pb-5 relative" key={idx}>
              <div className="col-span-2 grid grid-cols-2 gap-2 rounded border border-dashed p-2">
      
                    <NoFormInput
                      label="Müşteri Sipariş No"
                      value={values.priceLabel[idx]?.customerOrderNo}
                      onChange={(e) => {

                        if (values.changesForEveryProduct) {
                          setFieldValue(`priceLabel[${idx}].customerOrderNo`, e.target.value)
                        }
                      }}
                    />

                    <Input
                      label="Müşteri Sipariş Detayı"
                      name={`priceLabel[${idx}].customerOrderDetail`}
                      autoComplete="off"
                    />

                    <Input
                      label="Renk Kodu"
                      name={`priceLabel[${idx}].colorCode`}
                      maxLength="3"
                      autoComplete="off"
                    />
<DisliSayisiDetayModal
                  visible={isDisliModalVisible}
                  onCancel={handleDisliModalClose}
                  minWidth={'30%'} // modalType 4 ise minWidth 40%, aksi halde 30%
                  width={740}
                  title="Fiyat Etiketi Referans Ekle"
                >
                  <DrawerContent4 />
                </DisliSayisiDetayModal>

                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                  <div style={{ width: '75%' }}>
                  <div className={`${idx > 0 ? 'hidden' : ''}`}>
                      <SelectReferanceCode3 name={`priceLabel[${idx}].referanceCode`} />
                    </div>
           
                  </div>
                  <div style={{ width: '10%' }}>
                    <Button
                      type="button"
                      variant="blue"
                      onClick={handleDisliModalOpen} // Düğmeye tıklandığında modalı aç
                    >
                      Referans Ekle
                    </Button>
                  </div>
                  </div>
              
  

              </div>
              <div className="col-span-2 grid grid-cols-2 gap-x-5">
            <PriceSizes idx={idx} />
            
                <PriceSection idx={idx}/>
                <Separator className="col-span-2 h-[5px] bg-simsek" />
                  {idx > 0 && (
                    <Button
                      variant="destructive"
                      onClick={() => remove(idx)}
                      className="absolute -right-40 top-0"
                    >
                      <X className="w-4 h-4 text-white" />
                      Renk Sil
                    </Button>
                  )}
         
              </div>
  

            </div>
          ))}
          <div className="flex items-center gap-x-5">
            {!orderNo && (
              <Button
                type="submit"
                variant="softgreen"
                onClick={handleSaveOrder}
                disabled={isPending}
                className="w-full"
              >
                {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                Kaydet
              </Button>
            )}
            <Button
              type="button"
              onClick={orderNo ? handleUpdateOrder : handleSaveOrder}
              disabled={isPending || isPendingUpdate}
              className="w-full"
              variant={orderNo && 'softgreen'}
            >
              {(isPending || isPendingUpdate) && (
                <RotateCcw className="animate-spin mr-2" size={16} />
              )}
              {orderNo ? 'Güncelle' : "Artwork'e Git"}
            </Button>
            {orderNo && (
              <Link
                to={`/siparisler/artwork/${orderNo}?tab=${2}`}
                target="_blank"
                className="w-full"
              >
                <Button type="button" disabled={isPending} className="w-full">
                  {isPending && <RotateCcw className="animate-spin mr-2" size={16} />}
                  Artwork'e Git
                </Button>
              </Link>
            )}
            <Button
              type="button"
              variant="blue"
              onClick={() => {
                push({
                  ...values.priceLabel[0],
                  colorCode: '',
                  sizeDefectivePercent: '',
                  sizes: values.priceLabel[0].sizes.map((size) => ({
                    ...size,
                    amount: '',
                    amountWithDefect: ''
                  }))
                })
              }}
              className="w-full"
            >
              Renk Ekle
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}
