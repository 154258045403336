import React, { useRef, useEffect, useState } from 'react'

import useOrderStore from 'store/client/orderStore'

import { ReactComponent as MySVG20 } from '../../../../../assets/usuk.svg'
import { ReactComponent as MySVG249 } from '../../../../../assets/justus.svg'
import { ReactComponent as MySVG250 } from '../../../../../assets/justuk.svg'
import { ReactComponent as MyRed } from '../../../../../assets/waist/redlinemiddle.svg'
import { ReactComponent as MyZara } from '../../../../../assets/waist/zarabelt.svg'
import { ReactComponent as MySVG2900 } from '../../../../../assets/RDOLAR.svg'

export default function PageKall({
  sizeData,
  selectColor = 0,
  barcodeValue,
  data,
  indo,
  inx,
  szrgrp,
  integerpart3,
  currencysymbol3,
  integerpart4,
  currencysymbol4,
  decimalpart3,
  decimalpart4,
  rifd,
  SouthAmerica,
  harmacn
}) {
  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [scaleX, setScaleX] = useState(1)
  const department = data.department
  const barcodeRef = useRef(null)
  const [modifiedSvgText, setModifiedSvgText] = useState('')
  const [topValue, settopValue] = useState(16)
  const [leftValue, setleftValue] = useState(97.9)
  const [topValuexx, setTopValuexx] = useState(null);
  const [topValueSecondlinexx, setTopValueSecondlinexx] = useState(null);
  const [topThreeLine, setTopThreeLine] = useState(null);
  const [topFourLine, setTopFourLine] = useState(null);
  const [fontSize, setFontSize] = useState(20); // Başlangıç font boyutu
  const line2Ref = useRef(null);
  // Bölmek için "/" karakterini kullan
  const [brand, category, subCategory] = department.split(' / ')
  const filteredIndo = indo.filter((item) => item.amount !== '')
  const indexedIndo = indo.reduce((acc, item, index) => {
    if (item.amount !== '') {
      acc.push(index)
    }
    return acc
  }, [])

  const renderSVG = (integer3, decimal3, integer4, decimal4) => {
    console.log('Values received:', { integer3, decimal3, integer4, decimal4 })
    console.log('SUBCATEGORYY', subCategory)
    // Değerleri stringe çevirip kontrol ediyoruz
    const isInteger3Valid = String(integer3).trim() !== ''
    const isDecimal3Valid = String(decimal3).trim() !== ''
    const isInteger4Valid = String(integer4).trim() !== ''
    const isDecimal4Valid = String(decimal4).trim() !== ''
    if (SouthAmerica) {
      return <MySVG2900 width="23.256mm" height="14.496mm" />
    } else {
      switch (true) {
        case isInteger3Valid && isDecimal3Valid && isInteger4Valid && isDecimal4Valid:
          console.log('Case 1: All values are present (integer3, decimal3, integer4, decimal4)')
          return <MySVG20 width="21.256mm" height="14.496mm" />
        case isInteger3Valid && isDecimal3Valid && !isInteger4Valid:
          console.log(
            'Case 2: Only integer3 and decimal3 are present, integer4 and decimal4 are missing'
          )
          return <MySVG249 width="21.256mm" height="14.496mm" />
        case isInteger4Valid && isDecimal4Valid && !isInteger3Valid:
          console.log(
            'Case 3: Only integer4 and decimal4 are present, integer3 and decimal3 are missing'
          )
          return <MySVG250 width="21.256mm" height="14.496mm" />
        default:
          console.log('Default Case: None of the conditions are met', {
            integer3,
            decimal3,
            integer4,
            decimal4
          })
          return null // Hiçbir koşul sağlanmazsa hiçbir şey döndürme
      }
    }
  }
  const isNotEmpty = (val) => val !== undefined && val !== null && val !== '' && val !== 'null';

  useEffect(() => {
    if (referance) {
      console.log("buradaaas",referance.line)

      if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        !isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(283.21);
      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        !isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(272.21);
        setTopValueSecondlinexx(295.21);
      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(200);
      } else if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(272.21);
        setTopThreeLine(288);
        setTopFourLine(297.2);
      } else if (
        isNotEmpty(referance?.line1) &&
        !isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        !isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(300.21);
      } else if (
        isNotEmpty(referance?.line1) &&
        isNotEmpty(referance?.line2) &&
        isNotEmpty(referance?.line3) &&
        isNotEmpty(referance?.line4)
      ) {
        setTopValuexx(258);
        setTopValueSecondlinexx(276.3);
        setTopThreeLine(291);
        setTopFourLine(300.2);
      } else {
        // Default values if none of the conditions are met
      
      }
    }
  }, [referance]);
  
  
  useEffect(() => {
    const adjustFontSize = () => {
      if (line2Ref.current) {
        const { scrollHeight, offsetHeight } = line2Ref.current;
        console.log(`scrollHeight: ${scrollHeight}, offsetHeight: ${offsetHeight}`);
        if (offsetHeight > 51) {
          console.log(`Font size before adjustment: ${fontSize}`);
          setFontSize(17);
        }
        if (
          isNotEmpty(referance?.line1) &&
          isNotEmpty(referance?.line2) &&
          isNotEmpty(referance?.line3) &&
          isNotEmpty(referance?.line4)
        ) {
          setFontSize(17.2);
   
        } 
      }
   
    };
  
    // İlk render sonrası küçük bir gecikme ile kontrol
    setTimeout(adjustFontSize, 0);

  }, [line2Ref.current]);

  const valueBasedOnInx = indexedIndo[inx]

  const [currentShape, setCurrentShape] = useState(null)



  return (
    <div style={{ position: 'relative', width: '100%', height: '100%'}}>
  
      <div style={{ position: 'absolute', bottom: 26.21, left: 85.08, zIndex: 4 }}>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 16.5 }}>
          {szrgrp.sizes[valueBasedOnInx].usa}
        </span>
      </div>
      <div style={{ position: 'absolute', bottom: 26.21, left: 117.08, zIndex: 4 }}>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 16.5 }}>
          {szrgrp.sizes[valueBasedOnInx].mexico}
        </span>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 129.21,
          left: -4.08,
          zIndex: 4,
        }}
      >
      <MyRed style={{width:"52.2mm", height:"6.2mm"}}/>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 202.21,
          left: 35.08,
          zIndex: 4,
        }}
      >
      <MyZara style={{width:"32.2mm", height:"13.553mm"}}/>
      </div>

      <div
  style={{
    position: 'absolute',
    top: 320,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
  }}
>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 15,letterSpacing:1.2 }}>
        {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTH' : 'YEARS'}

        </span>

        
      </div>
      <div
  style={{
    position: 'absolute',
    top: 335,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
  }}
>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize:34 }}>
        {filteredIndo[inx]?.name}


        </span>

        
      </div>
   
      <div
        style={{
          position: 'absolute',
          top: 129.7,
          left: 94.08,
          zIndex: 4,
         
          transform: 'rotate(-180deg)' // Metni ters çevirir
        }}
      >
 

      </div>
        <div
        style={{
          position: 'absolute',
          top: 129.71,
          right: 13.08,
          zIndex: 4,
          transform: 'rotate(-180deg)' // Metni ters çevirir
        }}
      >
        <span
          className="helve"
          style={{ color: '#898A8D', fontSize: 12.5 }}
        >{`${data.articleNo}`}</span>
      </div>



      <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",position:"absolute",top:14.4
      ,right:14.4,zIndex:4,transform: 'rotate(-180deg)'}}>
      <div
        style={{

          display:"flex",
          justifyContent: "space-between",
          width:"100%",
          alignItems:"center",
          marginBottom:-6.9
        }}
      >
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 15.5,marginRight:10 }}>
        {filteredIndo[inx]?.name}
        </span>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10,letterSpacing:1,marginTop:3,paddingRight:1.4 }}>
        {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTH' : 'YEARS'}
        </span> 
      </div>
      <div
        style={{
     
          display:"flex",
          justifyContent: "space-between",
          width:"100%",
          alignItems:"center",
        }}
      >
      <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 15.5,letterSpacing:1.2,marginBottom:-1 }}>{filteredIndo[inx]?.cm?.slice(0, 3)}</span>
      <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10,letterSpacing:1,marginBottom:-4,marginRight:1.8}}>
        CM
        </span> 
      </div>

      </div>
      <div
  style={{
    position: 'absolute',
    top: topValuexx,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 300,
      color: '#898A8D',
      fontSize: fontSize,
      letterSpacing: '1px',
    }}
  >
  {referance?.line1}
  </span>
  
</div>

    
      <div
  style={{
    position: 'absolute',
    top: 87.61,
    right: 17.48,
    zIndex: 4,
    transform: 'rotate(-180deg)', // Metni ters çevirir
    display: 'flex', // Flex düzen
    alignItems: 'center', // Dikey hizalama
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 900,
      color: '#898A8D',
      fontSize: 10,
      letterSpacing: '1.3px',
      marginRight: '4.5px', // Aradaki boşluk
      wordSpacing:'1px'
    }}
  >
   {referance?.upperline1}
  </span>

</div>
    <div
  style={{
    position: 'absolute',
    top: 74.61,
    right: 17.48,
    zIndex: 4,
    transform: 'rotate(-180deg)', // Metni ters çevirir
    display: 'flex', // Flex düzen
    alignItems: 'center', // Dikey hizalama
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 900,
      color: '#898A8D',
      fontSize: 10,
      letterSpacing: '1.3px',
      marginRight: '4.5px', // Aradaki boşluk
      wordSpacing:'1px'
    }}
  >
   {referance?.upperline2}
  </span>

</div>
    <div
  style={{
    position: 'absolute',
    top: 61.61,
    right: 17.48,
    zIndex: 4,
    transform: 'rotate(-180deg)', // Metni ters çevirir
    display: 'flex', // Flex düzen
    alignItems: 'center', // Dikey hizalama
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 900,
      color: '#898A8D',
      fontSize: 10,
      letterSpacing: '1.3px',
      marginRight: '4.5px', // Aradaki boşluk
      wordSpacing:'1px'
    }}
  >
   {referance?.upperline3}
  </span>

</div>
{ referance?.line2 && (
        <div
          style={{
            position: 'absolute',
            top: topValueSecondlinexx,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
           ref={line2Ref}
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: fontSize,
              letterSpacing: '1px',
            }}
          >
             {referance?.line2}
          </span>
        </div>
      )}

      { referance?.line3 && (
        <div
          style={{
            position: 'absolute',
            top: topThreeLine,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: 8,
              letterSpacing: '0.2px',
            }}
          >
            {referance?.line3}
          </span>
        </div>
      )}
      {referance?.line4 && (
        <div
          style={{
            position: 'absolute',
            top: topFourLine,
            left: 0,
            right: 0,
            zIndex: 4,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <span
            className="helve"
            style={{
              fontWeight: 300,
              color: '#898A8D',
              fontSize: 8,
              letterSpacing: '0.2px',
            }}
          >
            {referance?.line4}
          </span>
        </div>
      )}
      <div
  style={{
    position: 'absolute',
    top: topValuexx,
    left: 0,
    right: 0,
    zIndex: 4,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
      textAlign: 'center',  // <-- Add this
  }}
>
  <span
    className="helve"
    style={{
      fontWeight: 300,
      color: '#898A8D',
      fontSize: fontSize,
      letterSpacing: '1px',
    }}
  >
  {referance?.line1}
  </span>
  
</div>
   
      <div
        style={{
     
          display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",position:"absolute",top:132.4
      ,left:17.4,zIndex:4,transform: 'rotate(-180deg)',
        }}
      >
          <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10 }}>
        {filteredIndo[inx]?.name}
        </span>
        <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10,letterSpacing:1,marginLeft:4.5 }}>
        {data.sizeGroup?.name?.includes('BEBEK') ? 'MONTH' : 'YEARS'}
        </span> 
            <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10.5,letterSpacing:1,marginLeft:4.5}}>/</span>

      <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10.5,letterSpacing:1,marginLeft:5}}>{filteredIndo[inx]?.cm?.slice(0, 3)}</span>
      <span className="helve" style={{ fontWeight: 600, color: '#898A8D', fontSize: 10.5,letterSpacing:1,marginLeft:4.4}}>
        CM
        </span> 
      </div>

      <div>
   
      </div>
    </div>
  )
}
