import React, { useRef, useEffect, useState } from 'react'
import Barcode from 'react-barcode'

import LabelCard from '../LabelCard'
import { GrupoLogo } from 'assets/icons'
import useOrderStore from 'store/client/orderStore'
import { ReactComponent as Zarafiyat } from '../../../../../assets/zarafiyat.svg'
import QrSvg from '@wojtekmaj/react-qr-svg'
import tshirtlogo from '../../../../../assets/tshirtinfo.svg'
import QRCodeStyling from 'qr-code-styling'
import { ReactComponent as MySVG } from '../../../../../assets/fsc.svg'
import { ReactComponent as MySVG2 } from '../../../../../assets/logolar-02.svg'
import { ReactComponent as MySVG3 } from '../../../../../assets/logolar-03.svg'
import { ReactComponent as MySVG4 } from '../../../../../assets/logolar-04.svg'
import { ReactComponent as MySVG5 } from '../../../../../assets/logolar-05.svg'
import { ReactComponent as MySVG6 } from '../../../../../assets/Varlık1.svg'
import { ReactComponent as ALARM } from '../../../../../assets/alarm (1).svg'
import { ReactComponent as MySVG7 } from '../../../../../assets/pricetopsvg/MAN.svg'
import { ReactComponent as MySVG8 } from '../../../../../assets/pricetopsvg/GLOBAL.svg'
import { ReactComponent as MySVG9 } from '../../../../../assets/pricetopsvg/DENIMWEAR.svg'
import { ReactComponent as MySVG10 } from '../../../../../assets/pricetopsvg/WOMANBASICKARE.svg'
import { ReactComponent as MySVG11 } from '../../../../../assets/pricetopsvg/WOMANBASICCIRCLE.svg'
import { ReactComponent as MySVG12 } from '../../../../../assets/pricetopsvg/WOMANBASICTRIANGLE.svg'
import { ReactComponent as MySVG13 } from '../../../../../assets/pricetopsvg/KIDSBABYBOY.svg'
import { ReactComponent as MySVG14 } from '../../../../../assets/pricetopsvg/KIDSBABYGIRL.svg'
import { ReactComponent as MySVG15 } from '../../../../../assets/pricetopsvg/KIDSBOYKARE.svg'
import { ReactComponent as MySVG16 } from '../../../../../assets/pricetopsvg/KIDSGIRLDAIRE.svg'
import { ReactComponent as MySVG17 } from '../../../../../assets/pricetopsvg/KIDSBABYUNIT.svg'

import { ReactComponent as MySVG18 } from '../../../../../assets/altbestlogo.svg'
import { ReactComponent as MyLogoHarman } from '../../../../../assets/altlogoharman.svg'

import { ReactComponent as MySVG19 } from '../../../../../assets/talla.svg'
import { ReactComponent as MySVG20 } from '../../../../../assets/usuk.svg'
import { ReactComponent as MySVG249 } from '../../../../../assets/justus.svg'
import { ReactComponent as MySVG250 } from '../../../../../assets/justuk.svg'
import { ReactComponent as MySVG21 } from '../../../../../assets/usamexuk.svg'
import { ReactComponent as MySVG22 } from '../../../../../assets/lineline.svg'
import { ReactComponent as MySVG2900 } from '../../../../../assets/RDOLAR.svg'
import { ReactComponent as MySVGTry } from '../../../../../assets/55110.svg'
import QRCodeComponenttt from './qrcodecomponent111mm'
import { ReactComponent as ZaraBomRed } from '../../../../../assets/DDRED.svg';
import { ReactComponent as Altustlinelinge } from '../../../../../assets/altustlinelinge.svg'

import { ReactComponent as ZaraFscrr } from '../../../../../assets/waist/fscrr.svg'
import { ReactComponent as ZaraFsctm } from '../../../../../assets/waist/fsctm.svg'

import { ReactComponent as BlackDenim } from '../../../../../assets/black-denimwear.svg' // SVG'yi React bileşeni olarak içe aktarma
import { IoTriangleOutline } from 'react-icons/io5'
import { IoTriangle } from 'react-icons/io5'
import { LiaCircleSolid } from 'react-icons/lia'
import { BiSolidCircle } from 'react-icons/bi'
import DynamicShrinkText from './DynamicShrinkText'
import DynamicShrinkAcc from './DynamicShrinkAcc'
import DynamicTextLeftt from './DynamicShrinkLefft'
import QRCodeComponent from './qrcodecomponent'
import RenderIndoItems from './RenderIndoItems'
import RenderIndoItemsAcc from './RenderIndoItemsAcc'

export default function Page1({
  sizeData,
  selectColor = 0,
  barcodeValue,
  data,
  indo,
  inx,
  szrgrp,
  integerpart3,
  currencysymbol3,
  integerpart4,
  currencysymbol4,
  decimalpart3,
  decimalpart4,
  rifd,
  SouthAmerica,
  harmacn
}) {
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [scaleX, setScaleX] = useState(1)
  const department = data.department
  const barcodeRef = useRef(null);
  const [modifiedSvgText, setModifiedSvgText] = useState("");
  const [topValue, settopValue] = useState(16) 
  const [leftValue, setleftValue] = useState(97.9)  


  // Bölmek için "/" karakterini kullan
  const [brand, category, subCategory] = department.split(' / ')
  const filteredIndo = indo.filter((item) => item.amount !== '')
  const indexedIndo = indo.reduce((acc, item, index) => {
    if (item.amount !== '') {
      acc.push(index)
    }
    return acc
  }, [])

  useEffect(() => {
    if (subCategory) {
      setCurrentShape(subCategory)
    }
  }, [subCategory])

  const handleSvgGenerated = (svgText) => {
    setModifiedSvgText(svgText);
  };

  useEffect(() => {

    // Belirtilen seçiciyle DOM öğesini yakalama
    const textElement = document.querySelector(
      '#root > div.min-h-screen.font-poppins > div > div:nth-child(3) > div:nth-child(1) > div > div > div > div:nth-child(3) > div:nth-child(3) > div.printable > div > div > div > div.tracking-\\[\\.02rem\\] > svg > g > text'
    );

    if (textElement) {
      // Margin top değerini güncelle
      //textElement.style.marginTop = '-10px ';
    } else {
    }
  }, []); // Sadece bileşen yüklendiğinde bir kez çalışacak


  useEffect(() => {
    if (subCategory) {
      if (category === 'Woman') {
        if (subCategory === 'WOMAN') {
          settopValue(16);
          setleftValue(97.9)
      
        } else if (subCategory === 'PREMIUM DENIM') {
          settopValue(16);
          setleftValue(97.9)
       
        } else if (subCategory === 'CIRCULAR WOMAN') {
          settopValue(16);
          setleftValue(97.9)
      
        } else if (subCategory === 'BASIC') {
            settopValue(18);
         setleftValue(99.8)
     
        } else if (subCategory === 'TRF') {
           settopValue(16);
          setleftValue(92.4)
        } else {
  
        }
      } else if (category === 'Man') {
        if (subCategory === 'DENIMWEAR') {
            settopValue(16);
          setleftValue(92.4)
        } else if (subCategory === 'MAN') {
              settopValue(18);
          setleftValue(99.8)
        } else if (subCategory === 'GLOBAL') {
          settopValue(16);
          setleftValue(98)
      
        } else {
      
        }
      } else if (category === 'Kids') {
        if (subCategory === 'GIRL') {
          settopValue(16);
          setleftValue(97.9)
        } else if (subCategory === 'KIDS A (BABY GIRL)') {
           settopValue(16);
          setleftValue(97.9)
        } else if (subCategory === 'BABY UNIQUE' || subCategory === 'KNIT' || subCategory === 'DENIM' || subCategory === 'OUTERWEAR') {
          settopValue(7);
          setleftValue(90.5)
        } else if (subCategory === 'BOY') {
          settopValue(18);
          setleftValue(99.8)
       
        } else if (subCategory === 'KIDS O (BABY BOY)') {
          settopValue(18.1);
          setleftValue(99.6)
        } else {
  
        }
      } else {
   
      }
        
    }
  }, [subCategory]);

  const renderSVG = (integer3, decimal3, integer4, decimal4) => {
    console.log("Values received:", { integer3, decimal3, integer4, decimal4 });
    console.log("SUBCATEGORYY",subCategory);
    // Değerleri stringe çevirip kontrol ediyoruz
    const isInteger3Valid = String(integer3).trim() !== '';
    const isDecimal3Valid = String(decimal3).trim() !== '';
    const isInteger4Valid = String(integer4).trim() !== '';
    const isDecimal4Valid = String(decimal4).trim() !== '';
  if(SouthAmerica)
  {
    return <MySVG2900 width="23.256mm" height="14.496mm" />;
    

  }
  else{
    switch (true) {
      case isInteger3Valid && isDecimal3Valid && isInteger4Valid && isDecimal4Valid:
        console.log("Case 1: All values are present (integer3, decimal3, integer4, decimal4)");
        return <MySVG20 width="21.256mm" height="14.496mm" />;
      case isInteger3Valid && isDecimal3Valid && !isInteger4Valid :
        console.log("Case 2: Only integer3 and decimal3 are present, integer4 and decimal4 are missing");
        return <MySVG249 width="21.256mm" height="14.496mm" />;
      case isInteger4Valid && isDecimal4Valid && !isInteger3Valid:
        console.log("Case 3: Only integer4 and decimal4 are present, integer3 and decimal3 are missing");
        return <MySVG250 width="21.256mm" height="14.496mm" />;
      default:
        console.log("Default Case: None of the conditions are met", { integer3, decimal3, integer4, decimal4 });
        return null; // Hiçbir koşul sağlanmazsa hiçbir şey döndürme
    }
  }
  

  };
 /* 
  <div style={{position:"absolute",left:12,top:-2}}>
  <MySVGTry style={{width:"55.2mm",height:"110.2mm"}}/>

  </div>
  */
  const renderShape = (currentShape, category) => {
    if (category === 'Woman') {
      switch (currentShape) {
        case 'WOMAN':
          return (
            <MySVG11 
            style={{
              width: '9.4mm',
              height: '9.4mm',

            }}


          />
          )
        case 'PREMIUM DENIM':
      

          return (
            <MySVG11 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}

          />
          )

        case 'BASIC':
    
          return (
            <MySVG10 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )
        case 'TRF':
          return (
            <MySVG12 
            style={{
              width: '12.4mm',
              height: '10.4mm',
            }}
          />
          )
        default:
          return (
            <></>
          )
      }
    } else if (category === 'Man') {
      switch (currentShape) {
        case 'DENIMWEAR':
          return (
            <MySVG9 
            style={{
              width: '12.4mm',
              height: '10.4mm',
            }}
          />
          )

        case 'MAN':
          return (
            <MySVG7 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        case 'GLOBAL':
          return (
            <MySVG8 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        default:
          return (
            <></>
          )
      }
    } else if (category === 'Kids') {
      switch (currentShape) {
        case 'GIRL':
          return (
            <MySVG16 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        case 'KIDS A (BABY GIRL)':
          return (
            <MySVG14 
            style={{
              width: '9.4mm',
              height: '9.4mm',
            }}
          />
          )

        case 'BABY UNIQUE':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'KNIT':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'DENIM':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'OUTERWEAR':
          return (
            <MySVG17 
            style={{
              width: '13.4mm',
              height: '11.4mm',
            }}
          />
          )
        case 'BOY':
          return (
            <MySVG15 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        case 'KIDS O (BABY BOY)':
          return (
            <MySVG13 
            style={{
              width: '8.4mm',
              height: '8.4mm',
            }}
          />
          )

        default:
          return (
            <></>
          )
      }
    } else {
      // Eğer kategori ve alt kategori eşleşmiyorsa varsayılan bir şekil döndür
      return (
        <></>
      )
    }
  }

  useEffect(() => {
    const adjustScale = () => {
      if (divRef.current && textRef.current) {
        const containerWidth = divRef.current.offsetWidth
        const textWidth = textRef.current.offsetWidth

        if (textWidth > containerWidth) {
          setScaleX(containerWidth / textWidth)
        } else {
          setScaleX(1)
        }
      }
    }

    adjustScale()
    window.addEventListener('resize', adjustScale) // Dinamik ekran boyutu için
    return () => window.removeEventListener('resize', adjustScale)
  }, [filteredIndo, inx])

  

  const valueBasedOnInx = indexedIndo[inx]

  const [currentShape, setCurrentShape] = useState(null)

  const { referance } = useOrderStore()
  const isBlack = referance?.backgroundColor === 'B'
  let shape



  return (
    <div>
      <div>
        <div>
    
          <div
            style={{
              position: 'absolute',
              top: -10,
              left: 10.5,

              justifyContent: 'center',
              display: 'grid',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {rifd && (
                <ALARM width="20.4mm" height="20.1mm" />
            )}
          </div>
   
          <div></div>
       

   <div
            style={{
              position: 'absolute',
              top: SouthAmerica ? 178 : 188.8, // SouthAmerica true ise 270, değilse 288.6
              left: SouthAmerica ?77.1:68.5,
              flexDirection: 'column',
              display: 'grid'
            }}
          >
  {renderSVG(integerpart3, decimalpart3, integerpart4, decimalpart4)}
          </div>
          <div
            style={{
              position: 'absolute',
              top:  integerpart3 === '' ? 209.9 : 181.9, // Koşullu top değeri
              left: 122,
              flexDirection: 'column',
              display: 'grid'
            }}
          >
     
            <div style={{ display: 'grid',marginTop:SouthAmerica?4:0,marginLeft:SouthAmerica?2:0 }}>
              <div style={{ display: 'grid' }}>
                {integerpart3 && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
               
                      <p className="helve-medium" style={{ fontSize: '14pt' }}>
                        {integerpart3}
                      </p>
                      <p
                        className="helve-medium"
                        style={{ fontSize: '9pt', marginTop:0, marginRight: '4px' }}
                      >
                        {decimalpart3}
                      </p>
                    </div>
                    <div>
              
                    </div>
                  </>
                )}
              </div>
            </div>
            <div style={{ display: 'grid', marginTop: 4 }}>
            {integerpart4 && !SouthAmerica && (
                <>
  

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
               
                    <p className="helve-medium" style={{ fontSize: '14pt' }}>
                      {integerpart4}
                    </p>
                    <p
                      className="helve-medium"
                      style={{ fontSize: '9pt', marginTop: 0, marginRight: '4px' }}
                    >
                      {decimalpart4}
                    </p>
                  </div>
                  <div>
              
                  </div>
                </>
              )}
            </div>
          </div>

          <div style={{width:"130px",height:"0.5px",backgroundColor:"black",position:"absolute",top:64.5,left:52.7,zIndex:4}}></div>
          <div style={{width:"130px",height:"0.5px",backgroundColor:"black",position:"absolute",top:99.5,left:52.7,zIndex:4}}></div>

          <div
            style={{
              position: 'absolute',
              top: 57.5,
              left: 83,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 75.2,
              
            }}
          >
         <MySVG19 width="19mm" height="1,359mm" ></MySVG19>
         </div>
          <div style={{ position: 'absolute', bottom:142.4, left: 30.6 }}>


    <MyLogoHarman width="46.800mm" height="8mm" />

          </div>
    
          <div style={{ position: 'absolute', bottom: 142.9, left: 28.6 }}>
  {data.company?.fsctype === "fscrr" ? (
    <ZaraFscrr width="4.855mm" height="7.724mm" />

  ) : data.company?.fsctype === "fsctm" ? (

    <ZaraFsctm width="4.855mm" height="7.724mm" />

  ) : null}
</div>
<div
  style={{
    position: 'absolute',
    bottom: data.company?.fsctype === "fsctm" ? 136.4 : 137,
    left: data.company?.fsctype === "fsctm" ? 37.80 : 39,
    letterSpacing: "0.15px"
  }}
>   
<span className='helve-roman' style={{fontSize:"2.96px",color:"#1d1d1b"}}>
{data.company?.fsc}
</span>
</div>

          <div style={{ position: 'absolute', top: -6, right:-5.8 }}>
            <QRCodeComponenttt  barcodeValue={barcodeValue}  onSvgGenerated={handleSvgGenerated} />
            {modifiedSvgText && (
              <div
  dangerouslySetInnerHTML={{ __html: modifiedSvgText }}

></div>

      )}
          </div>
          <DynamicShrinkAcc
            filteredIndo={filteredIndo}
            inx={inx}
            szrgrp={szrgrp}
            valueBasedOnInx={valueBasedOnInx}
            referance={referance}
            brand={brand}
            category={category}
            subCategory={subCategory}
          />
          <div style={{position:"absolute",top:71,left:89}}>
           <MySVG21 width="24.602mm" height="1.33mm"></MySVG21>
           </div>
           <div style={{position:"absolute",top:62.7,left:52.7}}>
           <Altustlinelinge width="34.414mm" height="10.414mm"></Altustlinelinge>
           </div>
          <div ref={barcodeRef} className="tracking-[.02rem]" style={{ position: 'absolute', top: 137.5, left: 55.2,width:"35mm",height:"10.310mm"}}>
            <Barcode
              value={barcodeValue.length === 13 ? barcodeValue : '0390547180002'}
              format="ITF14"
              width={0.926}
              height={25.9}
              marginLeft={1}
              marginRight={1}
              marginTop={1}
              marginBottom={1}
              background="transparent"
              lineColor={isBlack ? '#ffffff' : '#000000'}
              fontSize={9} // 7pt yaklaşık olarak 9px
              font="Helvetica Neue LT Std Roman"
            />
          </div>

          <div style={{ position: 'absolute', top: 104, left: 13,width:"95.5%" }}>
            <div
              style={{ display: 'flex', justifyContent: 'center', textAlign: 'center',width:"100%" }}
            >

<RenderIndoItemsAcc indo={filteredIndo} startIndex={valueBasedOnInx} indexxx={indexedIndo} />


            </div>
          </div>
          <div style={{ position: 'absolute', top: 123.5, left: 78 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="helve-roman" style={{ fontSize: '7pt', marginRight: '1px' }}>
                ART
              </p>  
              <p className="helve-roman" style={{ fontSize: '7pt', marginRight: '8px' }}>
                .:
              </p>
              <p className="helve-roman" style={{ fontSize: '7pt', marginLeft: '-7px' }}>
                {`${data.articleNo}/${data.priceLabel[selectColor].colorCode}`}
              </p>
            </div>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}
